import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

function Tabs({ tabs, normalStyle, activeStyle }) {
  return (
    <span>
      {tabs.map((tab) => (
        <NavLink
          key={tab.name}
          className={({ isActive }) => (isActive ? activeStyle : normalStyle)}
          to={tab.link}
        >
          {tab.name}
        </NavLink>
      ))}
    </span>
  );
}

Tabs.propTypes = {
  normalStyle: PropTypes.string,
  activeStyle: PropTypes.string,
  tabs: PropTypes.array
};

export default Tabs;
