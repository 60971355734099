import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  header: {
    height: '52px',
    alignItems: 'center',
    paddingRight: '26px',
    fontFamily: 'Roboto',
  }
}));

export default useStyle;
