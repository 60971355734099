import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

// user settings saved at the localStorage
export const DEFAULT_STORE = 'defaultStore';

export const defaultLocalStorageUserSettings = {
  clo: {
    pdfViewer: {},
    cloDetails: {
      downloadTopicsAnswers: {
        selectedTopic: [],
        isAllTopicsSelected: true
      }
    },
    cloTable: {
      pagination: {
        itemPerPage: 50
      },
      columnsStore: {
        defaultStore: {
          pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field] },
          visibilityColumns: {
            dealName: true,
            includedInPortfolio: true,
            latestMajorDocDate: true,
            latestMinorDocDate: true,
            latestIndenture: true,
            currency: true,
            product: false,
            dealType: true,
            cloStatus: true,
            latestMinorDoc: false,
            currentDocSource: false,
            otherInfo: false,
            dscore: false,
            esgScore: false
          },
          tableColumns: {
            dealName: {
              order: 0,
              width: 300
            },
            includedInPortfolio: {
              order: 1,
              width: 100
            },
            latestMajorDocDate: {
              order: 2,
              width: 120
            },
            latestMinorDocDate: {
              order: 3,
              width: 120
            },
            latestIndenture: {
              order: 4,
              width: 120
            },
            currency: {
              order: 5,
              width: 120
            },
            product: {
              order: 6,
              width: 120
            },

            dealType: {
              order: 7,
              width: 120
            },
            cloStatus: {
              order: 8,
              width: 120
            },
            latestMinorDoc: {
              order: 9,
              width: 120
            },
            currentDocSource: {
              order: 10,
              width: 120
            },
            otherInfo: {
              order: 11,
              width: 120
            },
            dscore: {
              order: 12,
              width: 120
            },
            esgScore: {
              order: 13,
              width: 120
            }
          }
        }
      }
    },
    topicsTable: {
      pagination: {
        itemPerPage: 50
      },
      columnsStore: {
        defaultStore: {
          pinnedColumns: {},
          visibilityColumns: {
            dealName: true,
            latestMajorDocDate: false,
            latestMinorDocDate: false,
            currency: true,
            product: false,
            dealType: true,
            latestMajorDoc: false,
            latestMinorDoc: false,
            otherInfo: false,
            cloStatus: false,
            dscore: false,
            esgScore: false,
            documentExecutionDate: true,
            documentName: true,
            answer: true,
            notes: true
          },
          tableColumns: {
            dealName: {
              order: 0,
              width: 300
            },
            latestMajorDocDate: {
              order: 1,
              width: 180
            },
            latestMinorDocDate: {
              order: 2,
              width: 180
            },
            currency: {
              order: 3,
              width: 120
            },
            product: {
              order: 4,
              width: 120
            },
            dealType: {
              order: 5,
              width: 120
            },
            latestMajorDoc: {
              order: 6,
              width: 170
            },
            latestMinorDoc: {
              order: 7,
              width: 170
            },
            otherInfo: {
              order: 8,
              width: 170
            },
            cloStatus: {
              order: 9,
              width: 170
            },
            dscore: {
              order: 10,
              width: 170
            },
            esgScore: {
              order: 11,
              width: 170
            },
            documentExecutionDate: {
              order: 12,
              width: 170
            },
            documentName: {
              order: 13,
              width: 270
            },
            answer: {
              order: 14,
              width: 200
            },
            notes: {
              order: 15,
              width: 200
            }
          }
        }
      }
    }
  },
  operations: {
    workflowTable: {
      pagination: {
        itemPerPage: 50
      },
      pinnedColumns: {},
      visibilityColumns: {
        dealName: true,
        product: true,
        dealType: true,
        currency: true,
        cloStatus: true,
        documentName: true,
        documentExecutionDate: true,
        isMajor: true,
        documentStatus: true,
        source: true,
        dataReceived: true,
        sourcingNotes: true,
        workflowNotes: true,
        contents: true,
        name: true,
        inputter: true,
        focusArea: true,
        reviewType: true,
        timeTaken: true,
        errorsCount: true,
        reviewNote: true,
        reviewStatus: true,
        created: true,
        finished: true,
        priority: true
      },
      tableColumns: {
        dealName: {
          order: 0,
          width: 300
        },
        product: {
          order: 1,
          width: 100
        },
        dealType: {
          order: 2,
          width: 150
        },
        currency: {
          order: 3,
          width: 120
        },
        cloStatus: {
          order: 4,
          width: 120
        },
        documentName: {
          order: 5,
          width: 120
        },
        documentExecutionDate: {
          order: 6,
          width: 120
        },
        isMajor: {
          order: 7,
          width: 120
        },
        documentStatus: {
          order: 8,
          width: 120
        },
        source: {
          order: 9,
          width: 120
        },
        dataReceived: {
          order: 10,
          width: 120
        },
        sourcingNotes: {
          order: 11,
          width: 120
        },
        workflowNotes: {
          order: 12,
          width: 120
        },
        contents: {
          order: 13,
          width: 120
        },
        name: {
          order: 14,
          width: 120
        },
        inputter: {
          order: 15,
          width: 120
        },
        reviewType: {
          order: 16,
          width: 120
        },
        focusArea: {
          order: 17,
          width: 120
        },
        timeTaken: {
          order: 18,
          width: 120
        },
        errorsCount: {
          order: 19,
          width: 120
        },
        reviewNote: {
          order: 20,
          width: 120
        },
        reviewStatus: {
          order: 21,
          width: 120
        },
        created: {
          order: 22,
          width: 120
        },
        finished: {
          order: 23,
          width: 120
        },
        priority: {
          order: 24,
          width: 120
        }
      }
    },
    analyticsTable: {
      pagination: {
        itemPerPage: 50
      }
    }
  },
  report: {
    pagination: {
      item_per_page: 10
    }
  },
  comparison: {
    tableColumns: {}
  }
};
