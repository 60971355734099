import { createFilterOptions } from '@mui/material';
import { areArraysEqual } from 'src/utils/common';
import { SHARED_PORTFOLIO } from 'src/utils/constants/stringConstants';

export const getUsedPortfolio = (portfolioList, sharedPortfolioList, activePortfolio) => {
  const userPortfolio = portfolioList?.portfolio.find(
    (portfolio) => portfolio?.portfolioDto?.id === activePortfolio?.id
  );
  const sharedPortfolio = sharedPortfolioList?.portfolio.find(
    (portfolio) => portfolio?.portfolioDto?.id === activePortfolio?.id
  );

  return {
    usedPortfolio: userPortfolio || sharedPortfolio,
    isUserPortfolio: !!userPortfolio && !sharedPortfolio,
    isSharedPortfolio: !!sharedPortfolio && !userPortfolio
  };
};

export const reduceDocsUnderDeals = (deals) =>
  deals.map((deal) => {
    const { documents, ...rest } = deal;
    return rest;
  });

export const rightTable = (deals, includeOnlyMajorDocuments) => {
  const majorDeals = deals.filter((deal) => !deal?.containsOnlyMinorDocuments);
  const minorDeals = deals.filter((deal) => deal?.containsOnlyMinorDocuments) || [];
  if (includeOnlyMajorDocuments) {
    return majorDeals;
  }
  return reduceDocsUnderDeals([...majorDeals, ...minorDeals]);
};

export const filterOptions = createFilterOptions({
  stringify: (option) => option.name
});

export const setArrFromTopicsList = (portfolioList, sharedPortfolioList) => {
  const userPortfolio = [...portfolioList.portfolio].map((portfolio) => ({
    ...portfolio.portfolioDto,
    category: 'My portfolios'
  }));

  const sharedPortfolio = [...sharedPortfolioList.portfolio].map((portfolio) => ({
    ...portfolio.portfolioDto,
    category: SHARED_PORTFOLIO
  }));

  return [...userPortfolio, ...sharedPortfolio];
};

export const areCloIdsEqual = (state, prevState, portfolioId) => {
  const getCloIds = (portfolios) =>
    portfolios
      ?.find((portfolio) => portfolio?.portfolioDto.id === portfolioId)
      ?.cloDtoList.map((clo) => clo.id) || [];

  return areArraysEqual(getCloIds(state), getCloIds(prevState));
};
