import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

export const AboutDealscribe = () => {
  const classes = useStyles();
  return (
    <>
      <Typography align="justify" className={classes.text}>
        Created in 2020, Dealscribe is a company dedicated to saving its users&apos; time. It grew from a
        realization that financial market participants were spending far too much of their time
        searching through documents to find the exact terms of each deal. Even the most expert
        individuals struggle to keep track of documentation differences across their portfolio of
        deals. The industry as a whole remains inefficient because the same document-trawling
        process is duplicated across dozens of different firms.
      </Typography>
      <Typography align="justify" className={classes.text}>
        To solve this problem, we have brought together a team of smart individuals from a variety
        of backgrounds. Guided by machine learning, they turn complex legal agreements into
        standardized data points and give our users the tools to find the information they need
        quickly. The result is a technology platform with a human heart. 20% of the work comes from
        the technology, 80% of value from human analysis.
      </Typography>
      <Typography align="justify" className={classes.text}>
        Dealscribe is based in London and New York and has the backing of a group of individuals
        with senior roles in the CLO industry. Its founder, Mike Peterson, has a track record of
        building successful data and information services that respond to the real needs of the
        market.
      </Typography>
    </>
  );
};
