import { makeStyles } from '@mui/styles';
import { theme } from 'src/theme';

export const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap'
  },
  location: {
    direction:"row",
    justifyContent:"space-between",
    alignItems:"center"
  },
  address: {
      width: '50%',
      align: 'left',
  },
  city: {
    variant: theme.body2,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 50,
    fontWeight: 500
  },
  country: {
    margin: '20px 0 40px 2px',
    variant: theme.body2,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 23,
    fontWeight: 500
  },
  addressText: {
    marginBottom: 5,
    variant: theme.body2,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 23,
    lineHeight: 1.5
  }
}));

