import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px'
  },
  cross: {
    cursor: 'pointer',
    '&:hover': {
      color: '#546E7A'
    }
  },
  addButton: {
    position: 'sticky',
    zIndex: 3,
    top: 0,
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      backgroundColor: 'transparent'
    }
  },
  applyButton: {
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  },
  contentBlock: {
    height: 356,
    padding: 0
  },
  fixedSearch: {
    width: '329px',
    margin: '20px',
    background: 'white',
    zIndex: 1
  },
  searchInput: {
    flexGrow: 1,
    color: '#444444',
    fontSize: '12px',
    lineHeight: '14px',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 3,
    border: '1px solid #D9D9D9',
    height: 36,
    width: 300,
    padding: '0 0 0 16px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '14px'
  },

  searchIcon: {
    marginRight: 16,
    color: '#80868B'
  },
  emptyMessage: {
    width: '310px',
    textAlign: 'center',
    marginTop: '70px'
  },
  listContainer: {
    overflowX: 'hidden !important'
  },
  labelRow: {
    display: 'flex',
    paddingLeft: '10px'
  },
  actionsOneBtn: {
    justifyContent: 'flex-end',
    padding: '16px 24px'
  },
  iconButton: {
    height: 24
  }
}));

export default useStyles;
