import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'src/redux/actions';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import UserPool from 'src/UserPool';
import { AppBar, Toolbar, Link, Button } from '@mui/material';
import { Input } from '@mui/icons-material';
import Tabs from 'src/components/Tabs';
import { postAnalytics } from 'src/services/api/analytics';
import useStyles from './styles';

export const Header = ({ className, hideMenu, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.session.user.role);

  const adminTab = [
    { name: 'Home', link: '/home' },
    { name: 'Operations', link: '/operations' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' }
  ];

  const editorTab = [
    { name: 'Home', link: '/home' },
    { name: 'Operations', link: '/operations' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' }
  ];

  const userTab = [
    { name: 'Home', link: '/home' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' }
  ];

  const guestTab = [
    { name: 'Home', link: '/home' },
    { name: 'About us', link: '/about-us' }
  ];

  const [currentTabs, setCurrentTabs] = useState(guestTab);

  const getUserTab = () => {
    switch (userRole) {
      case 'Admins':
        return adminTab;
      case 'Editors':
        return editorTab;
      case 'TrialUsers':
        return userTab;
      case 'Users':
        return userTab;

      default:
        return guestTab;
    }
  };

  useEffect(() => {
    setCurrentTabs(getUserTab());
    const cognitoUser = UserPool.getCurrentUser();
    setCurrentTabs(getUserTab());
    if (location.pathname === '/auth/login') {
      cognitoUser?.signOut();
      dispatch(logout());
      setCurrentTabs(guestTab);
    }
  }, [location.pathname, userRole]);

  const postAnalyticsEventHandler = async () => {
    const params = {
      event: 'LOGOUT'
    };

    await postAnalytics(params);
  };

  const handleLogout = async () => {
    await postAnalyticsEventHandler();
    const cognitoUser = UserPool.getCurrentUser();
    cognitoUser.signOut();
    dispatch(logout());
    const showAlert = localStorage.getItem('showAlert');
    const userSettings = localStorage.getItem('userSettings');
    localStorage.clear();
    localStorage.setItem('showAlert', showAlert);
    localStorage.setItem('userSettings', userSettings);
    navigate('/home');
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className={classes.headerContainer}>
          <Link className={classes.companyName} to="/home" component={NavLink}>
            <img src="/images/logos/dealscribe_logo.png" alt="Home page" className={classes.logo} />
          </Link>
          {!hideMenu && (
            <Tabs tabs={currentTabs} normalStyle={classes.tab} activeStyle={classes.active} />
          )}
          {!hideMenu && (
            <div className={classes.singOut}>
              {userRole === 'Admins' ||
              userRole === 'Editors' ||
              userRole === 'Users' ||
              userRole === 'TrialUsers' ? (
                <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
                  <Input className={classes.logoutIcon} />
                  Sign out
                </Button>
              ) : (
                <Button className={classes.logoutButton} color="inherit" onClick={handleLogin}>
                  <Input className={classes.logoutIcon} />
                  Sign in
                </Button>
              )}
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  hideMenu: PropTypes.bool
};
