import React from 'react';
import Tabs from 'src/components/Tabs';
import { useSelector } from 'react-redux';
import useStyles from './styles';

function TabsHeader() {
  const classes = useStyles();
  const userRole = useSelector((state) => state.session.user.role);

  const trialUserTabs = [
    { name: 'DEALS', link: '/deal/page/deals' },
    { name: 'PORTFOLIOS', link: '/deal/page/portfolios' },
    { name: 'TOPICS', link: '/deal/page/topics' },
    { name: 'COMPARISON', link: '/deal/page/comparison' }
  ];

  const commonTabs = [
    ...trialUserTabs,
    { name: 'TRACK CHANGES', link: '/deal/page/track-changes' }
  ];

  const tabs = userRole === 'TrialUsers' ? trialUserTabs : commonTabs;

  return <Tabs tabs={tabs} normalStyle={classes.tab} activeStyle={classes.active} />;
}

export default TabsHeader;
