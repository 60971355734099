import axios from 'axios';

export const getDeal = async (dealId) => {
  const { data } = await axios.get(`/clo/${dealId}`);
  return data;
};

export const getPrivateDeal = async () => {
  const { data } = await axios.get('/clo/private');
  return data;
};

export const putDealToUser = async (dealId, params) => {
  await axios.put(`/clo/${dealId}/user`, params);
};

export const getDealDocumentValidation = async (dealId, documentId) => {
  await axios.get(`/clo/${dealId}/document/${documentId}/validate`);
};

export const getDealValidation = async (dealId) => {
  await axios.get(`/clo/${dealId}/validate`);
};

export const getDealDocument = async (dealId) => {
  const { data } = await axios.get(`/clo/${dealId}/document`);
  return data;
};

export const putDeal = async (dealId, params) => {
  const { data } = await axios.put(`/clo/${dealId}`, params);
  return data;
};

export const patchDealProduct = async (dealId, params) => {
  const { data } = await axios.patch(`/clo/${dealId}/product`, params);
  return data;
};

export const deleteDeal = async (dealId) => {
  await axios.delete(`/clo/${dealId}`);
};

export const putDealFigi = async (dealId, search) => {
  const { data } = await axios.put(`/clo/${dealId}/figi?search=${search}`);
  return data;
};

export const getCurrentDealDocument = async (dealId) => {
  const { data } = await axios.get(`/clo/${dealId}/document/current`);
  return data;
};

export const postDealDocumentAnswers = async (params, documentId) => {
  const { data } = await axios.post('/answer', { ...params, documentId });
  return data;
};

export const postDealFilteredData = async (body, params) => {
  const { data } = await axios.post('/clo/filter', body, { params });
  return data;
};

export const getDealFilters = async (body) => {
  const { data } = await axios.post('/clo/filter/unique/values', body);
  return data;
};

export const putDealDocumentAnswers = async (params, documentId) => {
  const { data } = await axios.put('/answer', { ...params, documentId });
  return data;
};

export const postDealDocumentNote = async (params, documentId) => {
  const { data } = await axios.post('/note', { ...params, documentId });
  return data;
};

export const deleteDealDocumentAnswer = async (answerId) => {
  await axios.delete(`/answer/${answerId}`);
};

export const putDealDocumentNote = async (params, documentId) => {
  const { data } = await axios.put('/note', { ...params, documentId });
  return data;
};

export const deleteDealDocumentNote = async (noteId) => {
  await axios.delete(`/note/${noteId}`);
};

export const postDealDocumentCopy = async (documentId, params) => {
  await axios.post(`/document/${documentId}/topic/copy`, params);
};

export const putDealDocumentTopicScore = async (params, documentId) => {
  const { data } = await axios.put(`/document/${documentId}/topic/${params.topicId}/score`, {
    ...params,
    documentId
  });
  return data;
};

export const putDealDocumentESGScore = async (params, documentId) => {
  const { data } = await axios.put(`/document/${documentId}/topic/${params.topicId}/esg-score`, {
    ...params,
    documentId
  });
  return data;
};

export const getDealExport = async (body, params) => {
  const { data } = await axios.post('/clo/filter/export', body, { params });
  return data;
};

export const getDealsAndDocuments = async (productId) => {
  const { data } = await axios.get(`/clo/product/${productId}`);
  return data;
};
