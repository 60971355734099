import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Checkbox, Chip, TextField, createFilterOptions } from '@mui/material';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material';
import { cutStr } from '../../utils/common';

function OptionDropdown({
  options,
  selectedOptions,
  updateOptions,
  styles,
  loading,
  label,
  size,
  disabled
}) {
  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        disabled={loading}
        key={option.id}
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.name}
    </li>
  );

  const renderInput = (params) => <TextField {...params} label={label} variant="outlined" />;

  const handleChangeOptions = (event, changedOptions) => {
    updateOptions(changedOptions);
  };

  const removeOption = (id) => {
    updateOptions(selectedOptions.filter((option) => option.id !== id));
  };

  const renderTags = (values) =>
    values.map((value) => (
      <Chip
        disabled={disabled}
        key={value.id}
        label={cutStr(value.name, 35)}
        onDelete={() => {
          removeOption(value.id);
        }}
      />
    ));

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name
  });

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      style={styles}
      size={size}
      value={selectedOptions}
      filterOptions={filterOptions}
      options={options || []}
      disableClearable={disabled}
      getOptionDisabled={() => loading}
      onChange={handleChangeOptions}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOption}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
}

OptionDropdown.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  updateOptions: PropTypes.func,
  styles: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool
};

OptionDropdown.defaultProps = {
  loading: false
};

export default OptionDropdown;
