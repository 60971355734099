import { Typography, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const Address = ({classes, city, country, address, contact}) => (
  <Grid className={classes.address}>
    <Typography paragraph className={classes.city}>
      {city}
      <Typography className={classes.country}>{country}</Typography>
    </Typography>
    <Typography className={classes.addressText}>
      Address:
      {' '}
      {address}
    </Typography>
    <Typography className={classes.addressText}>
      Contact:
      {' '}
      {contact}
    </Typography>
  </Grid>
);

Address.propTypes = {
  classes: PropTypes.object,
  city: PropTypes.string,
  country: PropTypes.string,
  address: PropTypes.string,
  contact: PropTypes.string
};
