import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    margin: theme.spacing(0, 3, 0, 0),
    fontFamily: 'Roboto',
    color: '#242423',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    opacity: 0.6,
    textDecoration: 'none !important'
  },
  active: {
    paddingBottom: '22px',
    color: '#242423',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.0555556px',
    margin: theme.spacing(0, 3, 0, 0),
    borderBottom: '2px solid #242423',
    opacity: 1,
    textDecoration: 'none !important'
  },
  button: {
    height: 36,
    padding: theme.spacing(1, 2),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.75px',
    marginLeft: '18px'
  }
}));

export default useStyles;
