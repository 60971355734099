import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  row: {
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row'
  },
  img: {
    position: 'absolute',
    width: 250,
    height: 250
  },
  imgWrap: {
    display: 'inline-block',
    position: 'relative',
    width: 250,
    height: 250,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    color: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    opacity: 0,
    transition: 'opacity .5s ease',
    backgroundColor: '#a40606',
    paddingTop: 110,
    '&:hover': {
        opacity: 0.5
      }
  },
  fullName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: 1.5
  },
  role: {
    marginBottom: 15,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 1.5
  }
}));
