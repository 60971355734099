import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, LinearProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { initDefaultAxiosConfig } from './utils/axios';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme } from './theme';
import ScrollReset from './components/ScrollReset';

import routes from './routes';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import store from './redux/store/configureStore';

const history = createBrowserHistory();

initDefaultAxiosConfig(history);
export const router = createBrowserRouter(routes);

function App() {
  return (
    <StoreProvider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RouterProvider router={router} fallbackElement={<LinearProgress />}>
              <ScrollReset />
            </RouterProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StoreProvider>
  );
}

export default App;
