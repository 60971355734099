import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  bioPage: {
    padding: 80,
    marginBottom: 20
  },
  container: {
    direction: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  info: {
    width: 400
  },
  img: {
    width: 400,
    height: 400
  },
  fullName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1.5
  },
  role: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 30,
    lineHeight: 1.5
  },
  bio: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 1.5
  }
}));
