import React, { useReducer, Suspense } from 'react';
import { Divider, LinearProgress, Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TabsHeader from './TabsHeader/TabsHeader';
import useStyles from './styles';
import DealReducer, { initialState } from '../context/deal.reducer';
import DealContext from '../context/deal.context';

function DealPageTabsLayout() {
  const classes = useStyles();
  const [state, dispatch] = useReducer(DealReducer, initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DealContext.Provider value={{ state, dispatch }}>
      <Paper>
        <Grid container justifyContent="space-between" className={classes.header}>
          <Grid item>
            <TabsHeader />
          </Grid>
        </Grid>
        <Divider />
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          </Grid>
        </Grid>
      </Paper>
    </DealContext.Provider>
  );
}

export default DealPageTabsLayout;
