import createReducer from 'src/utils/createReducer';
import { v4 as uuid } from 'uuid';
import * as constants from './comparison.constants';
import { getReorderedDeals, getUpdatedDealsList, getUpdatedPortfolio } from '../utils';

export const initialState = {
  expandAllCategories: {
    state: false,
    count: 0,
    // unique name for subscribe
    id: ''
  },
  expandedRows: [],
  portfolioTopics: [],
  portfolioList: {
    portfolio: [],
    loading: true
  },
  sharedPortfolioList: {
    portfolio: [],
    loading: true
  },
  portfolioDealCompareList: {
    deals: [],
    filters: {},
    loading: false
  }
};

export default createReducer(initialState, {
  [constants.GET_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, loading: true }
    };
  },
  [constants.GET_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST](state) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, loading: true }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, portfolio: payload, loading: false }
    };
  },

  [constants.GET_PORTFOLIO_TOPICS_LIST](state, { payload }) {
    return {
      ...state,
      portfolioTopics: payload
    };
  },
  [constants.GET_DEAL_COMPARE_LIST](state) {
    return {
      ...state,
      portfolioDealCompareList: { ...state.portfolioDealCompareList, loading: true }
    };
  },
  [constants.GET_DEAL_COMPARE_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: payload,
        loading: false
      }
    };
  },
  [constants.GET_DEAL_COMPARE_LIST_ERROR](state) {
    return {
      ...state,
      portfolioDealCompareList: { ...state.portfolioDealCompareList, loading: false }
    };
  },
  [constants.UPDATE_DEAL_COMPARE_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: getUpdatedDealsList(state, payload),
        loading: false
      }
    };
  },
  [constants.UPDATE_COMPARE_FILTERS](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        filters: { ...state.portfolioDealCompareList.filters, ...payload, loading: false }
      }
    };
  },
  [constants.EXPAND_ALL_CATEGORIES](state) {
    const count =
      state.expandAllCategories.count > 0 ? 0 : Object.keys(state.portfolioTopics).length;
    return {
      ...state,
      expandAllCategories: { ...state.expandAllCategories, count, id: uuid() }
    };
  },
  [constants.INCREMENT_EXPANDED_CATEGORIES](state) {
    return {
      ...state,
      expandAllCategories: {
        ...state.expandAllCategories,
        count: state.expandAllCategories.count + 1
      }
    };
  },
  [constants.DECREMENT_EXPANDED_CATEGORIES](state) {
    return {
      ...state,
      expandAllCategories: {
        ...state.expandAllCategories,
        count: state.expandAllCategories.count - 1
      }
    };
  },
  [constants.GET_DEAL_FOR_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioAvailableForPortfolio: { ...state.portfolioAvailableForPortfolio, loading: true }
    };
  },
  [constants.GET_DEAL_FOR_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioAvailableForPortfolio: {
        ...state.portfolioAvailableForPortfolio,
        deals: payload,
        loading: false
      }
    };
  },
  [constants.SET_EXPANDED_ROW](state, { payload }) {
    return {
      ...state,
      expandedRows: state.expandedRows.map((row) => (row.id === payload.id ? payload : row))
    };
  },
  [constants.SET_EXPANDED_ROWS](state, { payload }) {
    return {
      ...state,
      expandedRows: payload
    };
  },
  [constants.UPDATE_PORTFOLIO_DEALS_ORDER](state, { payload }) {
    return {
      ...state,
      portfolioDealCompareList: {
        ...state.portfolioDealCompareList,
        deals: getReorderedDeals(state.portfolioDealCompareList.deals, payload)
      }
    };
  },
  [constants.UPDATE_DEALS_ORDER_IN_PORTFOLIO](state, { payload }) {
    return {
      ...state,
      portfolioList: {
        ...state.portfolioList,
        portfolio: getUpdatedPortfolio(state, payload),
        loading: false
      }
    };
  }
});
