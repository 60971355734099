import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none !important',
    backgroundColor: '#A3A99E !important',
    maxHeight: '64px'
  },
  flexGrow: {
    flexGrow: 1
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  singOut: {
    marginLeft: 'auto',
    color: '#242423'
  },
  logoutIcon: {
    marginRight: '8px'
  },
  logoutButton: {
    cursor: 'pointer'
  },
  companyName: {
    margin: '0px 32px 0px 8px !important',
    display: 'inline-block'
  },
  tabPanel: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  tab: {
    margin: '0 16px !important',
    color: '#242423 !important',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.0555556px',
    opacity: 0.6,
    textDecoration: 'none !important'
  },
  active: {
    paddingBottom: '22px',
    color: '#242423 !important',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.0555556px',
    margin: '0 16px !important',
    borderBottom: '2px solid #7A181A',
    opacity: 1,
    textDecoration: 'none !important'
  },
  logo: {
    width: 150
  }
}));

export default useStyles;
