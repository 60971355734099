import createReducer from 'src/utils/createReducer';
import * as constants from './deal.constants';

const initialSelectedFiltersState = {
  search: '',
  items: [],
  logicOperator: 'and'
};

export const initialState = {
  loadingDocuments: false,
  dealList: {
    deals: [],
    itemCounter: 0,
    itemsPerPage: 0,
    pageCounter: 0,
    sorting: '',
    availableFilters: {}
  },
  allDeals: [],
  selectedFilters: { ...initialSelectedFiltersState },
  loading: false,
  scrolling: false,
  headerHeight: 0,
  error: null,
  files: [],
  uploadingFiles: false,
  successUpload: false,
  uploadError: null,
  search: '',
  portfolioList: {
    portfolio: [],
    loading: true
  },
  sharedPortfolioList: {
    portfolio: [],
    loading: true
  },
  topics: {
    filters: { items: [], logicOperator: 'and' },
    portfolioFilters: {},
    documentFilters: { isCurrentDocument: true },
    availableFilters: {}
  },
  portfolioTopics: [],
  products: {
    data: [],
    loading: false
  },
  dealsForComparison: {
    data: []
  },
  comparisons: {
    data: []
  },
  frameLink: '',
  currentComparison: {}
};

export default createReducer(initialState, {
  [constants.SAVE_FILES](state, { payload }) {
    return {
      ...state,
      uploadingFiles: true,
      files: payload,
      uploadError: null
    };
  },
  [constants.SAVE_FILES_SUCCESS](state, { payload }) {
    return {
      ...state,
      uploadingFiles: false,
      files: [],
      successUpload: !!payload
    };
  },
  [constants.SAVE_FILES_ERROR](state, { error }) {
    return {
      ...state,
      uploadingFiles: false,
      uploadError: error
    };
  },
  [constants.GET_ALL_DEALS](state, { payload }) {
    const data = [...state.allDeals, ...payload];
    const allDeals = [...new Map(data.map((item) => [item.id, item])).values()];
    return {
      ...state,
      allDeals
    };
  },
  [constants.GET_DEALS](state) {
    return {
      ...state,
      dealList: { ...state.dealList },
      loading: true
    };
  },
  [constants.GET_DEALS_SUCCESS](state, { payload }) {
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, ...payload }
    };
  },
  [constants.UPDATE_DEAL](state, { payload }) {
    const newDeals = state.dealList.deals.map((deal) => {
      if (deal.id === payload.id) {
        return { ...deal, ...payload.data };
      }
      return { ...deal };
    });

    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, deals: newDeals }
    };
  },
  [constants.ENABLE_SHOW_DEAL_PORTFOLIO_STATUS](state, { payload: { enabledDealIds } }) {
    let newDeals = [...state.dealList.deals];
    if (enabledDealIds.length > 0) {
      newDeals = state.dealList.deals.map((deal) => ({
        ...deal,
        enableShowPortfolioStatus: enabledDealIds.includes(deal.id)
      }));
    } else {
      newDeals = state.dealList.deals.map((deal) => ({
        ...deal,
        enableShowPortfolioStatus: true
      }));
    }
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, deals: newDeals }
    };
  },
  [constants.GET_DEAL_FILTERS_LIST](state) {
    return {
      ...state,
      dealList: { ...state.dealList, availableFilters: { ...state.availableFilters } },
      loading: true
    };
  },
  [constants.GET_DEAL_FILTERS_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      loading: false,
      dealList: { ...state.dealList, availableFilters: { ...payload } }
    };
  },
  [constants.GET_DEALS_ERROR](state, { error }) {
    return {
      ...state,
      loading: false,
      error
    };
  },

  [constants.SEARCH_DEAL](state, { payload }) {
    return {
      ...state,
      search: payload
    };
  },
  [constants.SEARCH_DEAL_SUCCESS](state) {
    return {
      ...state,
      search: '',
      loading: false
    };
  },
  [constants.SEARCH_DEAL_ERROR](state, { error }) {
    return {
      ...state,
      search: '',
      loading: false,
      error
    };
  },
  [constants.GET_PORTFOLIO_LIST](state) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, loading: true }
    };
  },
  [constants.GET_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.DELETE_PORTFOLIO_FROM_LIST](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.filter(
      (item) => item.portfolioDto.id !== payload
    );

    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.EDIT_PORTFOLIO](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.map((item) =>
      item.id === payload.id ? payload : item
    );

    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.DELETE_DEAL_FROM_PORTFOLIO_LIST](state, { payload }) {
    const newPortfolioList = state.portfolioList.portfolio.map((item) => ({
      portfolioDto: item.portfolioDto,
      cloDtoList:
        item.portfolioDto.id === payload.portfolioId
          ? item.cloDtoList.filter((deal) => deal.id !== payload.dealId)
          : item.cloDtoList
    }));
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },
  [constants.ADD_PORTFOLIO](state, { payload }) {
    const newPortfolioList = [
      { portfolioDto: payload, cloDtoList: [] },
      ...state.portfolioList.portfolio
    ];
    return {
      ...state,
      portfolioList: { ...state.portfolioList, portfolio: newPortfolioList }
    };
  },

  [constants.GET_SHARED_PORTFOLIO_LIST](state) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, loading: true }
    };
  },
  [constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      sharedPortfolioList: { ...state.sharedPortfolioList, portfolio: payload, loading: false }
    };
  },
  [constants.UPDATE_TOPICS_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        filters: {
          items: [...payload.items] || [],
          logicOperator: payload.logicOperator,
          topicFilters: payload.topicFilters || []
        }
      }
    };
  },
  [constants.UPDATE_TOPICS_PORTFOLIO_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        portfolioFilters: { ...payload }
      }
    };
  },
  [constants.UPDATE_TOPICS_DOCUMENT_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        documentFilters: { ...state.topics.documentFilters, ...payload }
      }
    };
  },
  [constants.UPDATE_TOPICS_AVAILABLE_FILTERS](state, { payload }) {
    return {
      ...state,
      topics: { ...state.topics, availableFilters: payload }
    };
  },
  [constants.SELECT_OPTION_TO_SHOW_DEALS](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, includedInPortfolio: payload }
    };
  },
  [constants.DOWNLOAD_TOPIC](state, { payload }) {
    return {
      ...state,
      loadingDocuments: payload
    };
  },
  [constants.UPDATE_DEALS_SELECTED_FILTERS](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, ...payload }
    };
  },
  [constants.DELETE_ALL_DEALS_FILTERS](state) {
    return {
      ...state,
      selectedFilters: { ...initialSelectedFiltersState }
    };
  },
  [constants.DEAL_LIST_SEARCH](state, { payload }) {
    return {
      ...state,
      selectedFilters: { ...state.selectedFilters, search: payload }
    };
  },
  [constants.DEAL_LIST_SCROLL](state, { payload }) {
    return {
      ...state,
      scrolling: payload
    };
  },
  [constants.DEAL_HEADER_HEIGHT](state, { payload }) {
    return {
      ...state,
      headerHeight: payload
    };
  },
  [constants.LOAD_PRODUCTS](state) {
    return {
      ...state,
      products: { ...state.products, loading: true }
    };
  },
  [constants.LOAD_PRODUCTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      products: { ...state.products, loading: false, data: payload }
    };
  },

  [constants.GET_USER_COMPARISONS](state, { payload }) {
    return {
      ...state,
      comparisons: { ...state.comparisons, data: payload }
    };
  },
  [constants.GET_DEALS_FOR_COMPARISON](state) {
    return {
      ...state,
      dealsForComparison: { ...state.dealsForComparison, loading: true }
    };
  },

  [constants.GET_DEALS_FOR_COMPARISON_SUCCESS](state, { payload }) {
    return {
      ...state,
      dealsForComparison: { ...state.dealsForComparison, data: payload, loading: false }
    };
  },
  [constants.GET_LINK_FOR_FRAME](state, { payload }) {
    return {
      ...state,
      frameLink: payload
    };
  },
  [constants.GET_CURRENT_COMPARISON](state, { payload }) {
    return {
      ...state,
      currentComparison: payload
    };
  }
});
