import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Collapse,
  IconButton,
  InputBase,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { cloneDeep } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import usePrevious from 'src/utils/usePrevious';
import palette from '../theme/palette';
import { NumericInput } from './NumericInput';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { isArrayEqual, sortOrdersArray, trimValue } from '../utils/common';
import { INPUT_PROPS } from './constants';

const useStyles = makeStyles(() => ({
  addItemInput: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-between'
  },
  expand: {
    position: 'relative',
    maxWidth: '100px',
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  addItemIcon: {
    margin: '8px 9px 0'
  },
  deleteItemIcon: {
    margin: '8px 9px 0',
    cursor: 'pointer'
  },
  listContainer: {
    display: 'flex',
    'align-items': 'center'
  },
  list: {
    width: '100%'
  },
  rearrangeContainer: {
    display: 'flex',
    'flex-direction': 'column'
  },
  icon: {
    color: palette.icon
  },
  mdCol: {
    padding: '0 16px',
    textAlign: 'center'
  },
  noBorder: {
    border: 'none'
  },
  listItem: {
    width: 620
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  iconHover: {
    cursor: 'pointer',
    color: palette.icon
  }
}));

function EditableList({
  initialList,
  onListChange,
  sizeLimit,
  maxItemLength,
  errorOnLimit,
  errorOnUnique,
  dealTypes,
  categoryId
}) {
  const classes = useStyles();

  const [itemToAdd, setItemToAdd] = useState(null);
  const [currentList, setCurrentList] = useState(() => {
    const cachedList = cloneDeep(initialList);
    return cachedList.map((item) => ({
      ...item,
      key: uuid()
    }));
  });

  const prevDealTypes = usePrevious(dealTypes);
  const [selectedListItemKey, setSelectedListItemKey] = useState(null);

  const [openModalDeleteAnswer, setOpenModalDeleteAnswer] = useState(false);
  const [idDeletedAnswer, setIdDeletedAnswer] = useState();
  const [expandedItems, setExpandedItems] = useState([]);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const cachedList = currentList.map((value) => {
      const cachedItem = { ...value };
      delete cachedItem.key;
      delete cachedItem.selected;
      cachedItem.answer = trimValue(cachedItem.answer);
      return cachedItem;
    });

    onListChange(cachedList);
  }, [currentList]);

  const onInputChange = (event) => {
    const { value } = event.target;
    setItemToAdd(!value.trim() ? null : value);
  };

  const hasError = () =>
    currentList?.length === sizeLimit ||
    !!currentList?.filter((item) => item.answer === itemToAdd)?.length;

  const errorText = () => (currentList?.length === sizeLimit ? errorOnLimit : errorOnUnique);

  const getErrorText = () => (hasError() ? errorText() : '');

  const addListItem = () => {
    if (!itemToAdd || hasError()) {
      return;
    }
    setCurrentList((list) =>
      list.filter((item) => item.answer === itemToAdd).length
        ? [...list]
        : [
            ...list,
            {
              answer: itemToAdd,
              position: list.length > 0 ? Math.max(...list.map((o) => o.position)) + 1 : 1,
              key: uuid()
            }
          ]
    );
    setItemToAdd(null);
  };

  const updatePositionsInList = (isUp) => {
    const selectedItem = currentList.find((item) => item.key === selectedListItemKey);
    if (!selectedItem) {
      return;
    }
    const currentPos = selectedItem.position;
    const siblingPosition = isUp ? currentPos - 1 : currentPos + 1;
    const shouldChangePosition = isUp ? currentPos > 1 : currentPos < currentList.length;
    setCurrentList((prevList) =>
      prevList.map((item) => {
        if (
          shouldChangePosition &&
          (item.position === currentPos || item.position === siblingPosition)
        ) {
          return {
            ...item,
            position: item.position === currentPos ? siblingPosition : currentPos
          };
        }
        return item;
      })
    );
  };

  const removeListItem = () => {
    setCurrentList((list) => {
      const sortedList = sortOrdersArray(list, 'position');
      return sortedList
        .filter((item) => item.answer !== idDeletedAnswer.answer)
        .map((item, index) => ({
          ...item,
          position: index + 1
        }));
    });
    setOpenModalDeleteAnswer(false);
  };

  const handleBlur = ({ target }, answer, dealType) => {
    const scoreName = target.name.split('-')[1];
    setCurrentList((list) =>
      list.map((answerValue) => {
        if (answerValue.key === answer.key) {
          const defaultAnswerScores = answerValue.defaultAnswerScores || [];

          const existingScoreIndex = defaultAnswerScores?.findIndex(
            (score) => score.dealType.id === dealType.id
          );
          if (existingScoreIndex !== -1) {
            defaultAnswerScores[existingScoreIndex][scoreName] = target.value;
          } else {
            defaultAnswerScores.push({
              dealType,
              [scoreName]: target.value
            });
          }

          return { ...answerValue, defaultAnswerScores };
        }
        return answerValue;
      })
    );
  };

  useEffect(() => {
    if (!isArrayEqual(prevDealTypes, dealTypes)) {
      setCurrentList((list) =>
        list.map((answerValue) => ({
          answer: answerValue.answer,
          defaultAnswerScores: [],
          key: uuid(),
          position: answerValue.position
        }))
      );
    } else {
      setCurrentList((list) =>
        list.map((answerValue) => ({
          ...answerValue,
          key: uuid()
        }))
      );
    }
  }, [categoryId]);

  const handleChange = ({ target }, answerItem) => {
    setCurrentList((list) => {
      const editedAnswer = Object.prototype.hasOwnProperty.call(answerItem, 'id')
        ? list.find((item) => item.id === answerItem.id)
        : list.find((item) => item.position === answerItem.position);
      editedAnswer.answer = target.value;

      const notEditedAnswers = Object.prototype.hasOwnProperty.call(answerItem, 'id')
        ? list.filter((answer) => answer.id !== answerItem.id)
        : list.filter((answer) => answer.position !== answerItem.position);
      return [...notEditedAnswers, editedAnswer];
    });
  };

  const getSortedList = () => sortOrdersArray([...currentList], 'position');

  const getScoreValue = (answer, type, scoreType) => {
    const cachedAnswer = { ...answer };
    const editedAnswer =
      cachedAnswer?.defaultAnswerScores?.filter((score) => score.dealType?.id === type.id) || [];
    return editedAnswer.length > 0 ? editedAnswer[0][scoreType] : null;
  };

  const toggleCollapse = (listItemKey) => {
    if (expandedItems.includes(listItemKey)) {
      setExpandedItems(expandedItems.filter((key) => key !== listItemKey));
    } else {
      setExpandedItems([...expandedItems, listItemKey]);
    }
  };

  const onDeleteDefaultAnswer = (listItem) => {
    setOpenModalDeleteAnswer(true);
    setIdDeletedAnswer(listItem);
  };
  const dynamicStylesTopicScore =
    currentList.length > 1
      ? { width: 150, textAlign: 'center' }
      : { width: 140, textAlign: 'center' };

  const dynamicStylesEsgScore = currentList.length > 1 ? { width: 150 } : { width: 140 };

  const dynamicStylesDeleteIcon = currentList.length > 1 ? { right: 16 } : { right: 25 };

  return (
    <div>
      <ConfirmDeleteModal
        open={openModalDeleteAnswer}
        onClose={() => setOpenModalDeleteAnswer(false)}
        submit={removeListItem}
        title="Delete Default Answer"
        text="Are you sure you want to delete this default answer? It cannot be undone"
      />
      <div className={classes.addItemInput}>
        <TextField
          fullWidth
          multiline
          error={hasError()}
          helperText={getErrorText()}
          label="Answer"
          margin="normal"
          onChange={onInputChange}
          value={itemToAdd || ''}
          variant="outlined"
          inputProps={{
            maxLength: maxItemLength
          }}
        />
        <AddCircleIcon
          fontSize="medium"
          className={clsx(classes.addItemIcon, classes.icon, {
            [classes.iconHover]: itemToAdd
          })}
          onClick={addListItem}
        />
      </div>
      {!!currentList.length && (
        <div className={classes.listContainer}>
          {currentList.length > 1 && (
            <div className={classes.rearrangeContainer}>
              <ArrowDropUpIcon
                className={classes.icon}
                fontSize="large"
                onClick={() => updatePositionsInList(true)}
              />
              <ArrowDropDownIcon
                className={classes.icon}
                fontSize="large"
                onClick={() => updatePositionsInList(false)}
              />
            </div>
          )}
          <div className={classes.list}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 240 }}>Answer</TableCell>
                <TableCell style={{ width: 65, textAlign: 'center' }}>Type</TableCell>
                <TableCell style={dynamicStylesTopicScore}>Topics Score</TableCell>
                <TableCell style={dynamicStylesEsgScore}>ESG Score</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <List dense>
              {getSortedList().map((listItem) => (
                <ListItem
                  classes={{ selected: classes.active }}
                  className={classes.listItem}
                  key={listItem.key}
                  selected={selectedListItemKey === listItem.key}
                  onClick={() => setSelectedListItemKey(listItem.key)}
                >
                  <div style={{ display: 'flex', maxWidth: 220 }}>
                    <InputBase
                      multiline
                      style={{ width: 232, flex: '0 1 auto' }}
                      defaultValue={listItem.answer}
                      name="value"
                      onChange={(event) => handleChange(event, listItem)}
                    />
                    <IconButton
                      onClick={() => toggleCollapse(listItem.key)}
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expandedItems.includes(listItem.key)
                      })}
                      size="medium"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </div>
                  <Collapse in={expandedItems.includes(listItem.key)}>
                    <div>
                      {dealTypes.map((dealType) => (
                        <TableRow>
                          <TableCell className={classes.mdCol} style={{ width: 69 }}>
                            {dealType.name}
                          </TableCell>
                          <TableCell className={classes.mdCol}>
                            <TextField
                              fullWidth
                              variant="standard"
                              inputProps={INPUT_PROPS}
                              defaultValue={getScoreValue(listItem, dealType, 'topicScore')}
                              name={`${dealType.name}-topicScore`}
                              onBlur={(event) => handleBlur(event, listItem, dealType)}
                              InputProps={{
                                inputComponent: NumericInput,
                                disableUnderline: true,
                                style: {
                                  borderBottom: '1px solid rgba(250, 250, 250, 1.5)'
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.mdCol}>
                            <TextField
                              variant="standard"
                              fullWidth
                              inputProps={INPUT_PROPS}
                              defaultValue={getScoreValue(listItem, dealType, 'esgScore')}
                              name={`${dealType.name}-esgScore`}
                              onBlur={(event) => handleBlur(event, listItem, dealType)}
                              InputProps={{
                                inputComponent: NumericInput,
                                disableUnderline: true,
                                style: {
                                  borderBottom: '1px solid rgba(250, 250, 250, 1.5)'
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </div>
                  </Collapse>
                  <ListItemSecondaryAction style={dynamicStylesDeleteIcon}>
                    <DeleteIcon
                      className={clsx(classes.deleteItemIcon, classes.icon)}
                      onClick={() => onDeleteDefaultAnswer(listItem)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      )}
    </div>
  );
}

EditableList.propTypes = {
  initialList: PropTypes.array,
  sizeLimit: PropTypes.number.isRequired,
  maxItemLength: PropTypes.number.isRequired,
  errorOnLimit: PropTypes.string.isRequired,
  errorOnUnique: PropTypes.string.isRequired,
  onListChange: PropTypes.func,
  dealTypes: PropTypes.array,
  categoryId: PropTypes.number
};

EditableList.defaultProps = {
  initialList: []
};

export default EditableList;
