import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from 'src/assets/scss/modalWindow';

function UnsavedMessageModal({ open, onClose, onCancel }) {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span>Unsaved changes</span>
      <CloseIcon className={classes.cross} onClick={onCancel} />
    </div>
  );

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, classes.containerWidth408)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent className={classes.containerWidth408}>
          <Typography variant="body1" component="div">
            Your changes are not saved. Are you sure you would like to close the window?
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actionsRightPosition}>
          <Button
            style={{ backgroundColor: '#f5f5f5', color: 'black' }}
            variant="contained"
            onClick={onCancel}
          >
            CANCEL
          </Button>
          <Button color="primary" variant="contained" onClick={onClose}>
            CLOSE
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

UnsavedMessageModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onCancel: PropTypes.func
};

UnsavedMessageModal.defaultProps = {
  open: false
};

export default UnsavedMessageModal;
