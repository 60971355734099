import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Paper } from '@mui/material';
import { postAnalyticsEvent } from 'src/services/api/analytics';
import { AboutDealscribe } from './components/AboutDealscribe';
import { ContactUs } from './components/ContactUs';
import { Locations } from './components/Locations';
import { KeyIndividuals } from './components/KeyIndividuals';
import { useStyles } from './styles';

export const AboutUsPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const contactUsRef = useRef(null);
  const session = useSelector((state) => state.session);

  const scrollToBottom = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const postAnalyticsEventHandler = async () => {
    const data = {
      event: 'PAGE_ACCESS',
      details: 'About us'
    };
    await postAnalyticsEvent(data);
  };

  useEffect(() => {
    if (location.search === '?contact-us') scrollToBottom();
    if (session.user.email) postAnalyticsEventHandler();
  }, []);

  return (
    <Paper className={classes.aboutUsPage}>
      <Typography className={classes.heading}>ABOUT DEALSCRIBE</Typography>
      <AboutDealscribe />
      <Typography className={classes.heading}>KEY INDIVIDUALS</Typography>
      <KeyIndividuals />
      <Typography className={classes.heading}>LOCATIONS</Typography>
      <Locations />
      <ContactUs contactUsRef={contactUsRef} />
    </Paper>
  );
};
