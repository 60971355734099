import React from 'react';
import PropTypes from 'prop-types';

export const GoogleMap = ({ location }) => (
    <iframe
      title="google-map"
      style={{margin: 5}}
      width="400"
      height="400"
      loading="lazy"
      language="eng"
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_EMBED_MAPS_API}&q=${location}`}
    />
  );

GoogleMap.propTypes = {
  location: PropTypes.string
};
