import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  text: {
    margin: '20px 100px 20px 100px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 23,
    lineHeight: 1.5
  }
}));

