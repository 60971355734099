import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import {
  D_SCORE,
  ESG_SCORE,
  CATEGORY_SCORE,
  TOPIC_SCORE
} from 'src/utils/constants/stringConstants';
import useStyles from './styles';

function Score({ type, mark }) {
  const classes = useStyles();

  const getParams = (scoreType) => {
    switch (scoreType) {
      case 'dscore':
        return {
          name: 'D',
          style: classes.d,
          tooltip: D_SCORE
        };
      case 'esgScore':
        return {
          name: 'ESG',
          style: classes.esg,
          tooltip: ESG_SCORE
        };
      case 'categoryScore':
        return {
          name: '',
          style: classes.category,
          tooltip: CATEGORY_SCORE
        };
      case 'topicScore':
        return {
          name: '',
          style: classes.category,
          tooltip: TOPIC_SCORE
        };
      default:
        return {
          name: '',
          style: classes.category
        };
    }
  };

  return (
    <div className={clsx(classes.root, getParams(type).style)}>
      <Tooltip disableInteractive title={getParams(type).tooltip}>
        <p>
          {getParams(type).name} {mark}
        </p>
      </Tooltip>
    </div>
  );
}

Score.propTypes = {
  type: PropTypes.string,
  mark: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Score;
