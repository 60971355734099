import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  actionCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '12px'
  },
  alertInfo: {
    position: 'absolute',
    zIndex: '999',
    width: '320px',
    top: '90px',
    left: 'calc(50% - 160px)'
  },
  heading: {
    margin: '40px 0 40px 0',
    color: '#a40606',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 30,
    fontWeight: 500
  },
  linkHere: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
