import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import merge from 'lodash/merge';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import AuthGuard from 'src/components/AuthGuard';
import { showAlert } from 'src/redux/actions';
import { Header } from 'src/components/Header/Header';
import { defaultLocalStorageUserSettings } from 'src/utils/userLocalStorageSettings';
import useStyles from './styles';

function MainLayout() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.session.alert);

  const classes = useStyles();
  const location = useLocation();
  const isPdf = () => location?.pathname?.indexOf('document-pdf') === -1;

  const closeAlertMessage = () => {
    dispatch(showAlert({ isShown: false, type: '', message: '' }));
  };

  useEffect(() => {
    const localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    const mergedUserSettings = merge(defaultLocalStorageUserSettings, localStorageUserSettings);

    localStorage.setItem('userSettings', JSON.stringify(mergedUserSettings));
  });

  return (
    <AuthGuard>
      {alert.isShown && (
        <Snackbar
          className={classes.alertInfo}
          open={alert.isShown}
          autoHideDuration={4000}
          onClose={closeAlertMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={closeAlertMessage}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'white'
              }
            }}
            severity={alert.type}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
      <div className={clsx(isPdf() && classes.content)}>
        {isPdf() && <Header />}
        <Suspense fallback={<LinearProgress />}>
          <Outlet />
        </Suspense>
      </div>
    </AuthGuard>
  );
}

export default MainLayout;
