export const dealStatusList = [
  'Active',
  'Benchmark',
  'Draft',
  'Expired',
  'Inactive',
  'Private',
  'Not present'
].sort();

export const userDealStatusList = ['Active', 'Benchmark', 'Expired', 'Private'].sort();

export const dealDocumentStatusList = [
  'Active',
  'Draft',
  'Inactive',
  'Private',
  'Not present'
].sort();

export const dealTypeListDefaultAnswer = ['BSL', 'CBO', 'MM'].sort();

export const topicStatusList = ['Active', 'Inactive', 'Pending'].sort();

export const currency = ['USD', 'EUR', 'GBP'];

export const workFlowReviewTypeList = ['Inputter', 'Editor', 'Final checker'];
export const workFlowReviewStatusList = ['In progress', 'Finished'];

export const userRoles = [
  {
    name: 'Admins',
    value: 'Admins'
  },
  {
    name: 'Editors',
    value: 'Editors'
  },
  {
    name: 'Users',
    value: 'Users'
  },
  {
    name: 'Trial Users',
    value: 'TrialUsers'
  }
];

export const reportsTypeList = [
  'Scorecard',
  'Topic report',
  'Market standards report',
  'Manager report',
  'Other'
];

export const tocSectionType = ['Article', 'Section'];

export const listOfCommonFilterOperators = [
  {
    label: 'contains',
    value: 'contains',
    requiresFilterValue: true
  },
  {
    label: 'equals',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is any of',
    value: 'isAnyOf',
    requiresFilterValue: true
  },
  {
    label: 'is not any of',
    value: 'isNotAnyOf',
    requiresFilterValue: true
  }
];

export const listOfDateFilterOperators = [
  {
    label: 'equals',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: 'is on or after',
    value: 'isOnOrAfter',
    requiresFilterValue: true
  },
  {
    label: 'is on or before',
    value: 'isOnOrBefore',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  }
];

export const listOfNumbersFilterOperators = [
  {
    label: '=',
    value: 'equals',
    requiresFilterValue: true
  },
  {
    label: '>=',
    value: 'gte',
    requiresFilterValue: true
  },
  {
    label: '<=',
    value: 'lte',
    requiresFilterValue: true
  },
  {
    label: 'not empty',
    value: 'notEmpty',
    requiresFilterValue: false
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    requiresFilterValue: false
  }
];

export const listOfIsMajorFilterOperators = [
  {
    label: 'is',
    value: 'is',
    requiresFilterValue: true
  }
];

export const listOfSingleSelectFilterOperators = [
  {
    label: 'is',
    value: 'is',
    requiresFilterValue: true
  },
  {
    label: 'is not',
    value: 'not',
    requiresFilterValue: true
  },
  {
    label: 'is any of',
    value: 'isAnyOf',
    requiresFilterValue: true
  }
];

export const DEFAULT_SORTING_ORDER = ['asc', 'desc'];
