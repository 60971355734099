import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from 'src/assets/scss/modalWindow';

function ConfirmDeleteModal({ submit, open, onClose, title, text, isDeleting }) {
  const classes = useStyles();
  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span>{title}</span>
      <CloseIcon className={classes.cross} onClick={onClose} />
    </div>
  );

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, classes.containerWidth408)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent className={classes.containerWidth408}>
          <Typography variant="body1" component="div">
            {text}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actionsRightPosition}>
          <Button color="black" onClick={onClose}>
            CLOSE
          </Button>
          <Button
            className={classes.buttonConfirmation}
            variant="contained"
            onClick={submit}
            disabled={isDeleting}
            endIcon={isDeleting && <CircularProgress size={14} color="inherit" />}
          >
            DELETE
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  submit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  isDeleting: PropTypes.bool
};

ConfirmDeleteModal.defaultProps = {
  open: false
};

export default ConfirmDeleteModal;
