import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showAlert } from 'src/redux/actions';
import { Grid, Typography, Link, TextField, Button } from '@mui/material';
import { funcValidation } from 'src/utils/validation';
import ReCAPTCHA from 'react-google-recaptcha';
import { postContactUs } from 'src/services/api/email';
import { trimValue } from 'src/utils/common';
import { useStyles } from './styles';

export const ContactUs = ({ contactUsRef }) => {
  const SITE_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_PUBLIC_KEY;
  const classes = useStyles();
  const recaptchaRef = useRef();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    values: {
      subject: '',
      message: '',
      email: ''
    },
    touched: {},
    errors: {}
  });

  const [isNotHuman, setIsNotHuman] = useState(true);
  const [tokenRecaptcha, setTokenRecaptcha] = useState('');

  const validationFieldSettings = useCallback(
    () => ({
      subject: {
        required: 'subject'
      },
      email: {
        required: 'email',
        isEmail: true
      },
      message: {
        required: 'message'
      }
    }),
    []
  );

  useEffect(() => {
    const errors = {};
    const validationFields = validationFieldSettings();
    Object.keys(validationFields).forEach((fieldName) => {
      errors[fieldName] = funcValidation(validationFields[fieldName], formState.values[fieldName]);
    });

    setFormState((prevFormState) => ({
      ...prevFormState,
      errors
    }));
  }, [formState.values, validationFieldSettings]);

  const hasError = (fieldName) => !!formState.errors[fieldName] && !!formState.touched[fieldName];
  const getErrorText = (fieldName) => (hasError(fieldName) ? formState.errors[fieldName] : '');
  const hasSomeError = Object.keys(formState.errors).some((key) => formState.errors[key]);

  const handleChange = async (event) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const send = async () => {
    const data = {
      body: trimValue(formState.values.message),
      email: formState.values.email,
      subject: trimValue(formState.values.subject),
      token: tokenRecaptcha
    };

    try {
      await postContactUs(data);
      setFormState({
        values: {
          subject: '',
          email: '',
          message: ''
        },
        touched: {},
        errors: {}
      });
      dispatch(
        showAlert({ isShown: true, type: 'success', message: 'Message was successfully sent' })
      );
    } catch (error) {
      dispatch(
        showAlert({ isShown: true, type: 'error', message: 'Message wasn`t successfully sent' })
      );
    }

    recaptchaRef.current.reset();
    setIsNotHuman(true);
  };

  const onChange = (value) => {
    if (value) {
      setIsNotHuman(false);
      setTokenRecaptcha(value);
    } else setIsNotHuman(true);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={6} align="left">
        <Typography className={classes.heading} ref={contactUsRef}>
          CONTACT US
        </Typography>
        <TextField
          required
          error={hasError('email')}
          helperText={getErrorText('email')}
          fullWidth
          label="Email address"
          margin="normal"
          name="email"
          onChange={handleChange}
          value={formState.values.email || ''}
          variant="outlined"
          inputProps={{
            maxLength: 70
          }}
        />
        <TextField
          required
          error={hasError('subject')}
          helperText={getErrorText('subject')}
          fullWidth
          label="Subject"
          margin="normal"
          name="subject"
          onChange={handleChange}
          value={formState.values.subject || ''}
          variant="outlined"
          inputProps={{
            maxLength: 100
          }}
        />
        <TextField
          required
          error={hasError('message')}
          helperText={getErrorText('message')}
          fullWidth
          label="Message"
          margin="normal"
          name="message"
          multiline
          onChange={handleChange}
          value={formState.values.message || ''}
          variant="outlined"
          minRows={5}
          inputProps={{
            maxLength: 2000
          }}
        />
        <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_RECAPTCHA_KEY} onChange={onChange} />
        <div className={classes.actionCard}>
          <Button
            color="primary"
            variant="contained"
            disabled={hasSomeError || isNotHuman}
            onClick={send}
          >
            Send Message
          </Button>
        </div>
      </Grid>
      <Grid item xs={6} align="right">
        <Typography className={classes.heading}>TERMS AND CONDITIONS</Typography>
        <Typography variant="subtitle1">
          Terms and conditions of service usage could be found{' '}
          <Link
            className={classes.linkHere}
            target="_blank"
            href={`${process.env.PUBLIC_URL}/T&C and GDPR.pdf`}
          >
            here
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  );
};

ContactUs.propTypes = {
  contactUsRef: PropTypes.any
};
