import axios from 'axios';

export const postUser = async (params) => {
  const { data } = await axios.post('/user/filter', params);
  return data;
};

export const getInnerUser = async () => {
  const { data } = await axios.get('/user/inner');
  return data;
};

export const patchUser = async (params) => {
  const { data } = await axios.patch('/user/', params);
  return data;
};

export const postUserCreateNew = async (params) => {
  await axios.post('/user', params);
};

export const getUserExportWithFilters = async (body, requestId) => {
  const { data } = await axios.post(`/user/filter/export?requestId=${requestId}`, body);
  return data;
};

export const putUser = async (params) => {
  const { data } = await axios.put('/user/', params);
  return data;
};

export const deleteUser = async (email) => {
  await axios.delete('/user', { params: { userName: email } });
};

export const getUserLimits = async () => {
  const { data } = await axios.get('/user/current/limits');
  return data;
};
