import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { DataGridPro, GRID_TREE_DATA_GROUPING_FIELD, useGridApiRef } from '@mui/x-data-grid-pro';
import { putPortfolioOrder } from 'src/services/api/portfolio';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { showAlert } from 'src/redux/actions';
import usePrevious from 'src/utils/usePrevious';
import { isEqual } from 'lodash';
import useStyles from './styles';
import * as actionsCreator from '../context/comparison.actions';
import ComparisonContext from '../context/comparison.context';
import { prepareColumns } from './utils';
import { DealName } from './DealName';
import { CustomGroupingCol } from './CustomGroupingColumns';
import { slotProps } from './slotProps';

const prepareRows = (leftTable) => {
  const categoryRows = leftTable.map((category) => ({
    name: category.category,
    topics: category.topics,
    hierarchy: [category.category],
    id: category.id
  }));

  const topicRows = categoryRows.map((row) =>
    row.topics.map((topic) => ({
      hierarchy: [row.name, topic.name],
      topic,
      id: topic.id
    }))
  );

  return [...categoryRows, ...topicRows.flat()];
};

const CompareTable = ({
  leftTable,
  rightTable,
  lastDealElementRef,
  includeOnlyMajorDocuments,
  portfolio
}) => {
  const classes = useStyles();
  const {
    state: {
      expandAllCategories,
      portfolioDealCompareList: { deals }
    },
    dispatch
  } = useContext(ComparisonContext);
  const api = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const prevRows = usePrevious(rows);

  const getFilteredRows = (rowsToFilter) =>
    rowsToFilter.filter((row) => row.hierarchy.length === 1);

  const setRowsExpansion = (isExpanded) => {
    dispatch(
      actionsCreator.setExpandedRows(
        getFilteredRows(rows).map((row) => ({ id: row.id, isExpanded }))
      )
    );
  };

  const handleExpand = () => {
    dispatch(actionsCreator.expandAllCategories());
    if (expandAllCategories.count > 0) {
      setRowsExpansion(false);
    } else {
      setRowsExpansion(true);
    }
  };

  useEffect(() => {
    setRows(prepareRows(leftTable));
    if (includeOnlyMajorDocuments) {
      const docs = rightTable.map((deal) => deal.documents.filter((doc) => doc.isMajor));
      setColumns(prepareColumns(docs, classes, lastDealElementRef));
    } else {
      setColumns(prepareColumns(rightTable, classes, lastDealElementRef));
    }
  }, [includeOnlyMajorDocuments, rightTable.length, deals]);

  useEffect(() => {
    if (portfolio.id && rows.length) {
      api.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
    }
  }, [portfolio.id]);

  const hasRowIdsChanged = () => {
    const prevFilteredRowIds = getFilteredRows(prevRows).map((row) => row.id);
    const filteredRowIds = getFilteredRows(rows).map((row) => row.id);
    return !isEqual(filteredRowIds, prevFilteredRowIds);
  };

  useEffect(() => {
    if (!prevRows || prevRows.length !== rows.length || hasRowIdsChanged()) {
      setRowsExpansion(false);
    }
  }, [rows]);

  const groupingColDef = {
    headerName: (
      <Grid container justifyContent="center" alignItems="center">
        <IconButton onClick={handleExpand}>
          {expandAllCategories.count > 0 ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
        <span>Category</span>
      </Grid>
    ),
    field: 'category',
    width: 200,
    minWidth: 150,
    cellClassName: ({ rowNode }) =>
      rowNode.children ? classes.categoryCol : classes.categoryColOpen,
    headerClassName: clsx(classes.categoryCol, classes.bgGray),
    hideDescendantCount: true,
    renderCell: (params) => <CustomGroupingCol {...params} />
  };

  const columnGroupingModel = () =>
    rightTable.map((deal, i) => ({
      groupId: deal.id,
      description: deal.dealName,
      headerClassName: i % 2 === 0 ? classes.bgLightGray : classes.bgGray,
      renderHeaderGroup: () => <DealName doc={deal} name={deal.dealName} />,
      children: deal.documents ? deal.documents.map((doc) => ({ field: doc.name })) : []
    }));

  const applyClasses = (elems) => {
    const overrides = elems.reduce(
      (state, el) => ({
        ...state,
        [`${el}`]: classes.bgGray
      }),
      {}
    );
    overrides.columnHeaderTitleContainer = classes.center;
    return overrides;
  };

  const dispatchRedux = useDispatch();

  const saveColumnOrder = async (state, orderedColumns) => {
    const params = {
      cloOrders: orderedColumns.map((deal) => ({
        position: state?.columns?.orderedFields?.indexOf(deal.dealName),
        cloId: deal.cloId
      })),
      portfolioId: portfolio.id
    };

    try {
      await putPortfolioOrder(params);

      dispatch(actionsCreator.updatePortfolioDealsOrder(params));
      dispatch(actionsCreator.updateDealsOrderInPortfolio(portfolio.id, params));
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error?.response?.data.message })
      );
    }
  };

  const setOrderedColumns = (state) => {
    const orderedCols = [];
    state?.columns?.orderedFields?.forEach((el) => {
      rightTable.forEach((deal) => (deal.dealName === el ? orderedCols.push(deal) : null));
    });

    setColumns(prepareColumns(orderedCols, classes, lastDealElementRef));
    saveColumnOrder(state, orderedCols);
  };
  const handleOrderChange = async () => {
    const currentState = api.current.exportState();
    setOrderedColumns(currentState);
  };

  return (
    <div className={classes.tableContainer}>
      {!!rows.length && (
        <DataGridPro
          className={classes.root}
          classes={applyClasses(['root', 'cell', 'columnHeader--emptyGroup'])}
          treeData
          apiRef={api}
          onColumnOrderChange={() => handleOrderChange()}
          disableColumnReorder={includeOnlyMajorDocuments || portfolio?.isShared}
          getTreeDataPath={(row) => row.hierarchy}
          groupingColDef={groupingColDef}
          rows={rows}
          columns={columns}
          pinnedColumns={{ left: [GRID_TREE_DATA_GROUPING_FIELD, 'topics'] }}
          columnHeaderHeight={95}
          getRowHeight={({ model }) => (model.hierarchy?.length > 1 ? 95 : 60)}
          sx={slotProps}
          disableColumnMenu
          hideFooter
          defaultGroupingExpansionDepth={expandAllCategories.count > 0 ? -1 : 0}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel()}
        />
      )}
    </div>
  );
};

CompareTable.propTypes = {
  leftTable: PropTypes.array,
  rightTable: PropTypes.array,
  lastDealElementRef: PropTypes.func,
  includeOnlyMajorDocuments: PropTypes.bool,
  portfolio: PropTypes.object
};

export default CompareTable;
