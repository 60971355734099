import React from 'react';
import { biographiesData } from './biosgraphiesData';
import { KeyIndividualsItem } from './KeyIndividualsItem';

export function KeyIndividuals() {
  return (
    <>
      <KeyIndividualsItem biographies={biographiesData.slice(0, 3)} />
      <KeyIndividualsItem biographies={biographiesData.slice(3)} />
    </>
  );
}
