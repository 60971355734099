import createReducer from 'src/utils/createReducer';
import * as constants from './operations.constants';
import { initFilters } from '../UserManagement/usersConstants';

const initialStateUsersData = {
  users: [],
  filter: initFilters,
  availableFilters: {}
};

const initialWorkflowSelectedFilters = {
  search: '',
  items: [],
  logicOperator: 'and'
};

export const initialState = {
  downloadUsers: false,
  downloadTopics: false,
  downloadAnalytics: false,
  importTopics: false,
  topics: {
    search: '',
    data: [],
    loading: false,
    error: null,
    collapsedCategories: {}
  },
  users: {
    search: '',
    totalNumbers: 0,
    data: {
      ...initialStateUsersData,
      sortByColumn: 'activeUntil',
      sortDirectionOrder: 'ASC'
    },
    loading: false,
    error: null
  },
  categories: {
    data: [],
    loading: false
  },
  privateContent: {
    search: '',
    data: [],
    loading: false
  },
  analytics: {
    data: {
      analyticsList: []
    },
    loading: false
  },
  workflowList: {
    reviewWorkflowRecordList: [],
    itemCounter: 0,
    itemsPerPage: 0,
    pageCounter: 0,
    sorting: '',
    availableFilters: {},
    loading: false
  },
  workflowSelectedFilters: {
    search: '',
    items: [],
    logicOperator: 'and'
  },
  dealTypes: {
    data: [],
    loading: false
  },
  groupedByProductDealTypes: {
    data: [],
    loading: false
  },
  products: {
    data: [],
    loading: false
  }
};

export default createReducer(initialState, {
  [constants.LOAD_OPERATIONS_TOPICS](state) {
    return {
      ...state,
      topics: { ...state.topics, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_TOPICS_SUCCESS](state, { payload }) {
    return {
      ...state,
      topics: { ...state.topics, loading: false, data: payload }
    };
  },
  [constants.SEARCH_OPERATIONS_TOPICS](state, { payload }) {
    return {
      ...state,
      topics: { ...state.topics, search: payload }
    };
  },

  [constants.COLLAPSE_CATEGORY](state, { payload }) {
    return {
      ...state,
      topics: {
        ...state.topics,
        collapsedCategories: { ...state.topics.collapsedCategories, ...payload }
      }
    };
  },
  [constants.LOAD_OPERATIONS_USERS](state) {
    return {
      ...state,
      users: { ...state.users, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_USERS_SUCCESS](state, { payload }) {
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        data: {
          ...state.users.data,
          users: [...payload.users]
        },
        totalNumbers: payload.users.length
      }
    };
  },
  [constants.OPERATIONS_EDIT_USER](state, { payload }) {
    const users = [...state.users.data.users];
    const editedData = users.map((n) => {
      if (n.email === payload.email) {
        return payload;
      }
      return n;
    });

    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        data: { ...state.users.data, users: [...editedData] }
      }
    };
  },
  [constants.RESET_OPERATIONS_USERS](state) {
    return {
      ...state,
      users: { ...state.users, data: { ...state.users.data, ...initialStateUsersData } }
    };
  },
  [constants.FILTER_OPERATIONS_USER_RESET](state) {
    return {
      ...state,
      users: {
        ...state.users,
        data: { ...state.users.data, filter: initFilters, availableFilters: {} }
      }
    };
  },
  [constants.DELETE_OPERATIONS_USER](state, { payload }) {
    const users = [...state.users.data.users];
    const index = users.findIndex((item) => item.email === payload);
    if (index > -1) {
      state.users.data.users.splice(index, 1);
    }

    return {
      ...state,
      users: { ...state.users, totalNumbers: state.users.data.users.length }
    };
  },
  [constants.ADD_OPERATIONS_USER](state, { payload }) {
    return {
      ...state,
      users: { ...state.users, data: [payload, ...state.users.data] }
    };
  },
  [constants.SEARCH_OPERATIONS_USER](state, { payload }) {
    return {
      ...state,
      users: { ...state.users, search: payload }
    };
  },
  [constants.SEARCH_OPERATIONS_USER_RESET](state) {
    return {
      ...state,
      users: { ...state.users, search: '' }
    };
  },
  [constants.UPDATE_USERS_FILTERS](state, { payload }) {
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        data: {
          ...state.users.data,
          filter: { ...state.users.data.filter, ...payload }
        }
      }
    };
  },
  [constants.UPDATE_USERS_AVAILABLE_FILTERS](state, { payload }) {
    const {
      activeUntilFrom,
      activeUntilTo,
      emailList,
      companyList,
      firstNameList,
      lastNameList,
      roleList,
      statusList
    } = payload;

    return {
      ...state,
      users: {
        ...state.users,
        data: {
          ...state.users.data,
          ...state.users.data.availableFilters,
          availableFilters: {
            emailList,
            companyList,
            firstNameList,
            lastNameList,
            roleList,
            statusList,
            activeUntilFrom,
            activeUntilTo
          }
        }
      }
    };
  },
  [constants.UPDATE_USERS_BULK_FILTERS](state) {
    return {
      ...state,
      users: {
        ...state.users,
        data: {
          ...state.users.data,
          filter: {
            ...state.users.data.filter,
            companyList: [],
            activeUntilFrom: '',
            activeUntilTo: ''
          }
        }
      }
    };
  },
  [constants.SET_USERS_SORT_BY](state, { payload }) {
    return {
      ...state,
      users: {
        ...state.users,
        data: {
          ...state.users.data,
          sortByColumn: payload
        }
      }
    };
  },
  [constants.SET_USERS_SORT_DIRECTION](state, { payload }) {
    return {
      ...state,
      users: {
        ...state.users,
        data: {
          ...state.users.data,
          sortDirectionOrder: payload
        }
      }
    };
  },

  [constants.LOAD_OPERATIONS_ANALYTICS](state) {
    return {
      ...state,
      analytics: { ...state.analytics, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_ANALYTICS_SUCCESS](state, { payload }) {
    return {
      ...state,
      analytics: { ...state.analytics, loading: false, data: payload }
    };
  },
  [constants.DOWNLOAD_OPERATIONS_ANALYTICS](state, { payload }) {
    return {
      ...state,
      downloadAnalytics: payload
    };
  },
  [constants.LOAD_OPERATIONS_CATEGORIES](state) {
    return {
      ...state,
      categories: { ...state.categories, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_CATEGORIES_SUCCESS](state, { payload }) {
    return {
      ...state,
      categories: { ...state.categories, loading: false, data: payload }
    };
  },
  [constants.DOWNLOAD_OPERATIONS_USERS](state, { payload }) {
    return {
      ...state,
      downloadUsers: payload
    };
  },
  [constants.DOWNLOAD_OPERATIONS_TOPICS](state, { payload }) {
    return {
      ...state,
      downloadTopics: payload
    };
  },
  [constants.IMPORT_OPERATIONS_TOPICS](state, { payload }) {
    return {
      ...state,
      importTopics: payload
    };
  },
  [constants.LOAD_OPERATIONS_PRIVATE_DEALS](state) {
    return {
      ...state,
      privateContent: { ...state.privateContent, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_PRIVATE_DEALS_SUCCESS](state, { payload }) {
    return {
      ...state,
      privateContent: { ...state.privateContent, loading: false, data: payload }
    };
  },
  [constants.SEARCH_OPERATIONS_PRIVATE_CONTENT](state, { payload }) {
    return {
      ...state,
      privateContent: { ...state.privateContent, search: payload }
    };
  },

  [constants.GET_WORKFLOW](state) {
    return {
      ...state,
      workflowList: { ...state.workflowList, loading: true }
    };
  },
  [constants.GET_WORKFLOW_SUCCESS](state, { payload }) {
    return {
      ...state,
      workflowList: { ...state.workflowList, ...payload, loading: false }
    };
  },
  [constants.GET_WORKFLOW_FILTERS_LIST](state) {
    return {
      ...state,
      workflowList: {
        ...state.workflowList,
        loading: true
      }
    };
  },
  [constants.GET_WORKFLOW_FILTERS_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      workflowList: {
        ...state.workflowList,
        availableFilters: { ...payload },
        loading: false
      }
    };
  },
  [constants.UPDATE_WORKFLOW_SELECTED_FILTERS](state, { payload }) {
    return {
      ...state,
      workflowSelectedFilters: { ...state.workflowSelectedFilters, ...payload }
    };
  },
  [constants.DELETE_WORKFLOW_SELECTED_FILTERS](state) {
    return {
      ...state,
      workflowSelectedFilters: { ...initialWorkflowSelectedFilters }
    };
  },
  [constants.LOAD_OPERATIONS_DEAL_TYPES](state) {
    return {
      ...state,
      dealTypes: { ...state.dealTypes, loading: true }
    };
  },
  [constants.LOAD_OPERATIONS_DEAL_TYPES_SUCCESS](state, { payload }) {
    return {
      ...state,
      dealTypes: { ...state.dealTypes, loading: false, data: payload }
    };
  },
  [constants.LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES](state) {
    return {
      ...state,
      groupedByProductDealTypes: { ...state.groupedByProductDealTypes, loading: true }
    };
  },
  [constants.LOAD_GROUPED_BY_PRODUCT_DEAL_TYPES_SUCCESS](state, { payload }) {
    return {
      ...state,
      groupedByProductDealTypes: {
        ...state.groupedByProductDealTypes,
        loading: false,
        data: payload
      }
    };
  },
  [constants.LOAD_PRODUCTS](state) {
    return {
      ...state,
      products: { ...state.products, loading: true }
    };
  },
  [constants.LOAD_PRODUCTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      products: { ...state.products, loading: false, data: payload }
    };
  }
});
