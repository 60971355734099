// Answer
export const ANSWER_MAX_LENGTH = 500;
export const NOTES_MAX_LENGTH = 500;
export const DISPLAYED_TOPIC_ANSWER_LENGTH = 60;

// Analytics
export const TABLE_ROWS_PER_PAGE = [50, 100, 200, 500];

// Common
export const downloadRetryLimit = 10;
export const downloadRetryLimit15 = 15;

// Reports
export const MAX_REPORT_PDF_SIZE = 10485760;

// PDF
export const MAX_PDF_SELECTION_CHARACTERS = 5000;

export const SELECTION_PAGE_LIMIT = 5;

export const PAGE_OVERSCAN_COUNT = 10;

// TrialUser
export const DOWNLOAD_FILE_LIMIT = 10;

// Track Changes
export const COMPARISONS_ROWS_PER_PAGE = [10, 20, 50];
