import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#f4f6f8',
    padding: '84px 30px 0'
  },
  alertInfo: {
    top: '100px'
  }
}));

export default useStyles;
