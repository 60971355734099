import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { biographiesData } from '../biosgraphiesData';

export const BioPage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const biography = biographiesData.find((i) => i.id === +id);
  const { fullName, role, img, bio } = biography;
  return (
    <Paper className={classes.bioPage}>
      {biography && (
        <Grid container className={classes.container}>
          <Grid item className={classes.info}>
            <Typography className={classes.fullName}>{fullName}</Typography>
            <Typography className={classes.role}>{role}</Typography>
            <Typography className={classes.bio} align="justify">
              {bio}
            </Typography>
          </Grid>
          <Grid item>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className={classes.img} src={img} />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
