import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import {
  DECIMAL_SCALE,
  EDITABLE_LIST_DECIMAL_SCALE,
  EDITABLE_LIST_MAX_LIMIT,
  EDITABLE_LIST_MIN_LIMIT,
  MAX_LIMIT,
  MIN_LIMIT
} from './constants';

export const NumericInput = React.forwardRef((props, ref) => {
  const { onChange, isCustomFields, isEditableList, decimalScale, ...other } = props;

  const defineDecimalScale = () => {
    if (decimalScale >= 0) {
      return decimalScale;
    }
    return isEditableList ? EDITABLE_LIST_DECIMAL_SCALE : DECIMAL_SCALE;
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      isNumericString
      allowNegative={!!isCustomFields}
      decimalScale={defineDecimalScale()}
      isAllowed={(values) => {
        const { value } = values;
        if (isEditableList) {
          return value < EDITABLE_LIST_MAX_LIMIT && value > EDITABLE_LIST_MIN_LIMIT;
        }
        return value < MAX_LIMIT && value > MIN_LIMIT;
      }}
    />
  );
});

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isCustomFields: PropTypes.bool.isRequired,
  isEditableList: PropTypes.bool.isRequired,
  decimalScale: PropTypes.number
};
