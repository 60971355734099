import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  aboutUsPage: {
    padding: 80,
    marginBottom: 20
  },
  heading:{
    margin: '40px 20px 40px 20px',
    textAlign: 'center',
    color: '#a40606',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 33,
    fontWeight: 500
  }
}));
