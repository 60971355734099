export const biographiesData = [
  {
    id: 1,
    fullName: 'Mike Peterson',
    role: 'Founder',
    img: '/images/biographies/bio_1.jpg',
    bio: `Mike Peterson is a former financial journalist and invests in information businesses. Before 
    founding Dealscribe in 2020, he served as managing editor of Creditflux, a credit market 
    news, data and events business he set up in 2001, and which is now part of Acuris. As a 
    reporter, Mike has been closely involved in the structured credit market since the late 1990s 
    and has moderated and hosted numerous industry discussion panels and events focussing 
    on CLOs.`
  },
  {
    id: 2,
    fullName: 'Paul Tilt',
    role: 'Head of Research',
    img: '/images/biographies/bio_2.jpg',
    bio: `Paul Tilt heads Dealscribe's London-based research team. Before joining Dealscribe, he 
    spent over 10 years in data leadership roles, most recently head of fund research at Acuris, 
    responsible for the strategic development of infrastructure, private equity and private credit 
    fund coverage. Prior to Acuris, Paul was head of research at infrastructure publication Inframation, managing a global team of analysts and launching a suite of new products. He 
    was involved in the post-acquisition integration of the company by Mergermarket in 2013.`
  },
  {
    id: 3,
    fullName: 'Johan Zafra',
    role: 'Director of Sales',
    img: '/images/biographies/bio_3.jpg',
    bio: `Based in New York City, Johan Zafra is responsible for Dealscribe's commercial initiatives
     and relations with subscribers. Before joining Dealscribe in 2020, he spent three years at 
     Creditflux as a sales director where he solidified his knowledge of CLO market participants 
     and rooted strong relationships within the industry.  Prior to that, he spent several years in 
     business development roles for B2B and B2C companies.`
  },
  {
    id: 4,
    fullName: 'Hiram Hamilton',
    role: 'Director and Investor',
    img: '/images/biographies/bio_4.jpg',
    bio: `Hiram Hamilton is co-head of structured credit at Alcentra, a global asset management firm 
    focussed on sub-investment grade corporate credit. He oversees approximately $8 billion of
    investments in structured products, with a particular focus on CLO investments, across 
    Alcentra’s funds. Before joining Alcentra in 2008, he served as head of the CLO structuring 
    and origination business at Morgan Stanley in London. Prior to 1999, Hiram was an analyst 
    at Prudential Securities in the asset backed securities group.`
  },
  {
    id: 5,
    fullName: 'Matthew A Miller',
    role: 'Consultant and Investor',
    img: '/images/biographies/bio_5.JPG',
    bio: `Matthew Miller is a well known figure in the North American leveraged loan and CLO 
    market. He co-led well respected CLO management businesses in the US, first at TCW and 
    more recently at Crescent Capital, before leaving to pursue other interests. He has recently 
    set up a small CLO consultancy business, CLOutions!. He has spoken at numerous industry 
    events and was active in the LSTA, chairing its CLO Committee. He currently sits on the
    Board of the Business Loans Coalition, as well as the Shaker Heritage Society.`
  },
  {
    id: 6,
    fullName: 'David Quirolo',
    role: 'Investor',
    img: '/images/biographies/bio_6.jpg',
    bio: `David Quirolo is a London-based partner in the capital markets group at global law firm 
    Cadwalader. Focussing his practice primarily on CLOs, securitization, asset finance and 
    repackaging transactions involving various asset types in the United States and Europe, 
    David represents banks, arrangers and asset managers in a variety of structured finance 
    transactions. David has been referred to as “Europe’s leading lawyer in the CLO field", and 
    he also represents the Loan Market Association in relation to regulation affecting the CLO 
    market.`
  },
  {
    id: 7,
    fullName: 'Sean M Solis',
    role: 'Investor',
    img: '/images/biographies/bio_7.jpg',
    bio: `Sean Solis is a partner in the New York office of global law firm Milbank and a member of 
    the firm’s alternative investments practice. He is widely recognized as one of North 
    America's leading attorneys in the representation of CLO collateral managers and CLO 
    equity investors. Sean has counseled US and international asset managers and hedge funds 
    in connection with tens of billions of dollars of transactions across a wide variety of CLOs 
    and other complex structured credit financing arrangements.`
  }
];
