import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: '0px 24px 24px 24px',
    color: '#242423 !important',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    opacity: 0.6,
    textDecoration: 'none !important'
  },
  active: {
    padding: '0px 24px 18px 24px',
    color: `${theme.palette.primary.main} !important`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 500,
    opacity: 1,
    fontSize: '14px',
    lineHeight: '16px',
    textDecoration: 'none !important'
  }
}));

export default useStyles;
