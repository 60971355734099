import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { funcValidation, asyncFuncValidation } from 'src/utils/validation';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { userRoles } from 'src/utils/constants/listsConstants';
import { removeExtraSpaceFromObjectValues } from 'src/utils/common';
import useStyles from 'src/assets/scss/modalWindow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { DIALOG_PROPS } from 'src/theme/constants';
import OptionDropdown from '../../../../components/OptionDropdown';

function AddUserModal({
  open,
  onClose,
  className,
  createNewUser,
  checkUniqueUserEmail,
  allProducts
}) {
  const classes = useStyles();

  const activeUntilDate = moment().add(1, 'years').format('YYYY-MM-DD');

  const initialState = {
    values: {
      email: '',
      company: '',
      role: 'Users',
      activeUntil: activeUntilDate,
      products: []
    },
    touched: {},
    blurred: {},
    onTouchErrors: {},
    onBlurErrors: {}
  };

  const [formState, setFormState] = useState({ ...initialState } || {});

  const onTouchValidationSettings = useCallback(
    () => ({
      email: {
        required: 'email'
      },
      role: {
        required: 'role'
      },
      activeUntil: {
        required: 'activeUntil'
      },
      company: {
        required: 'company'
      }
    }),
    []
  );

  const onBlurAsyncValidationSettings = useCallback(
    () => ({
      email: {
        uniqueEmail: (email) => checkUniqueUserEmail(email)
      }
    }),
    [checkUniqueUserEmail]
  );

  const onBlurSyncValidationSettings = useCallback(
    () => ({
      email: {
        isEmail: true
      }
    }),
    []
  );

  const validate = useCallback((settings, values) => {
    const errors = {};
    Object.keys(settings).forEach((fieldName) => {
      const error = funcValidation(settings[fieldName], values[fieldName]);
      if (error) {
        errors[fieldName] = error;
      }
    });
    return errors;
  }, []);

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...initialState,
      onBlurErrors: prevFormState.onBlurErrors,
      onTouchErrors: prevFormState.onTouchErrors
    }));
  }, [open]);

  useEffect(() => {
    const onTouchSettings = onTouchValidationSettings();
    const onTouchErrors = validate(onTouchSettings, formState.values);
    setFormState((prevFormState) => ({
      ...prevFormState,
      onTouchErrors
    }));
  }, [formState.values, onTouchValidationSettings, validate]);

  const asyncBlurValidate = async (blurredFields, blurredValues) => {
    const settings = onBlurAsyncValidationSettings();
    const errors = {};
    const promises = [];
    blurredFields.forEach((fieldName) => {
      if (settings[fieldName]) {
        const result = asyncFuncValidation(settings[fieldName], blurredValues[fieldName]);
        result.then((resp) => {
          errors[fieldName] = resp;
        });
        promises.push(result);
      }
    });
    await Promise.all(promises);
    setFormState((prevFormState) => ({
      ...prevFormState,
      onBlurErrors: {
        ...prevFormState.onBlurErrors,
        ...errors
      }
    }));
  };

  const syncBlurValidate = () => {
    const onBlurSettings = onBlurSyncValidationSettings();
    const blurredFields = Object.keys(formState.blurred).filter(
      (field) => formState.blurred[field]
    );
    if (blurredFields.length === 0) {
      return;
    }
    const blurredValues = {};
    // eslint-disable-next-line no-return-assign
    blurredFields.forEach((field) => (blurredValues[field] = formState.values[field]));
    const onBlurErrors = validate(onBlurSettings, blurredValues);
    if (Object.keys(onBlurErrors).length === 0) {
      asyncBlurValidate(blurredFields, blurredValues);
    } else {
      setFormState((prevFormState) => ({
        ...prevFormState,
        onBlurErrors
      }));
    }
  };

  useEffect(() => {
    syncBlurValidate();
  }, [formState.blurred]);

  useEffect(() => {
    const timer = setTimeout(() => {
      syncBlurValidate();
    }, 1000);

    return () => clearTimeout(timer);
  }, [formState.values]);

  const hasTouchErrors = (fieldName) =>
    !!formState.blurred[fieldName] && !!formState.onTouchErrors[fieldName];

  const hasBlurErrors = (fieldName) =>
    !!formState.blurred[fieldName] && !!formState.onBlurErrors[fieldName];

  const hasError = (fieldName) => hasTouchErrors(fieldName) || hasBlurErrors(fieldName);

  const getErrorText = (fieldName) => {
    if (hasTouchErrors(fieldName)) {
      return formState.onTouchErrors[fieldName] || '';
      // eslint-disable-next-line no-else-return
    } else if (hasBlurErrors(fieldName)) {
      return formState.onBlurErrors[fieldName] || '';
    } else {
      return '';
    }
  };

  const updateFormValue = (name, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value
      },
      touched: {
        ...prevFormState.touched,
        [name]: !!value.length
      }
    }));
  };

  const updateProducts = (updatedProducts) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        products: updatedProducts
      },
      touched: {
        ...prevFormState.touched,
        products: !!updatedProducts.length
      }
    }));
  };

  const handleChange = ({ target }) => {
    updateFormValue(target.name, target.value);
  };

  const handleDateChange = async (date, name) => {
    updateFormValue(name, moment(date).format('YYYY-MM-DD'));
  };

  const handleBlur = ({ target }) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      blurred: {
        ...prevFormState.blurred,
        [target.name]: true
      }
    }));
  };

  const isSaveDisabled = Object.keys(formState.values).some(
    (key) => formState.onTouchErrors[key] || formState.onBlurErrors[key]
  );

  const save = () => {
    const isValidEmail = checkUniqueUserEmail(formState.values.email);
    if (isValidEmail === true) {
      const isChanged = false;
      const params = removeExtraSpaceFromObjectValues(formState.values);
      const productIds = params.products.map((product) => product.id);
      createNewUser({ ...params, products: productIds });
      onClose(isChanged);
    }
  };

  const onCloseHandler = () => {
    const isChanged = Object.values(formState.touched).some((value) => value);
    onClose(isChanged);
  };

  if (!open) {
    return null;
  }

  const headerModal = (
    <div className={classes.headerModal}>
      <span>Add New User</span>
      <CloseIcon className={classes.cross} onClick={onCloseHandler} />
    </div>
  );

  return (
    <Modal onClose={onCloseHandler} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={headerModal} />
        <Divider />
        <CardContent>
          <Typography component="div">
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
              error={hasError('email')}
              helperText={getErrorText('email')}
              label="Email"
              margin="normal"
              name="email"
              value={formState.values.email || ''}
              variant="outlined"
              inputProps={{
                maxLength: 70
              }}
            />
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError('company')}
              helperText={getErrorText('company')}
              fullWidth
              required
              label="Client"
              margin="normal"
              name="company"
              value={formState.values.company || ''}
              variant="outlined"
              inputProps={{
                maxLength: 70
              }}
            />
            <TextField
              required
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              label="Role"
              name="role"
              value={formState.values.role}
              select
            >
              {userRoles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                autoOk
                invalidDateMessage=""
                margin="normal"
                fullWidth
                error={hasError('activeUntil')}
                helperText={getErrorText('activeUntil')}
                views={['year', 'month', 'day']}
                inputFormat="yyyy-MMM-dd"
                variant="inline"
                inputVariant="outlined"
                label="Active Until"
                name="activeUntil"
                DialogProps={DIALOG_PROPS}
                value={formState.values.activeUntil || ''}
                onChange={(date) => handleDateChange(date, 'activeUntil')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="medium">
                        <CalendarTodayIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    size="medium"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <OptionDropdown
              size="medium"
              options={allProducts}
              selectedOptions={formState.values.products}
              updateOptions={updateProducts}
              styles={{ margin: '16px 0 8px' }}
              label="Products"
            />
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button color="black" onClick={onCloseHandler}>
            CLOSE
          </Button>
          <Button disabled={isSaveDisabled} color="primary" variant="contained" onClick={save}>
            ADD
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

AddUserModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  createNewUser: PropTypes.func,
  open: PropTypes.bool,
  checkUniqueUserEmail: PropTypes.func,
  allProducts: PropTypes.array
};

AddUserModal.defaultProps = {
  open: false
};

export default AddUserModal;
