import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Link } from '@mui/material';
import { useStyles } from './styles';

export const KeyIndividualsItem = ({ biographies }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.row} container spacing={2}>
      {biographies.map((item) => (
        <Grid key={item.id} item>
          <Link className={classes.imgWrap} href={`/about-us/bio/${item.id}`}>
            <img className={classes.img} src={item.img} alt={item.fullName} loading="lazy" />
            <Typography className={classes.overlay} align="center">
              READ BIO
            </Typography>
          </Link>
          <Typography className={classes.fullName} align="center">
            {item.fullName}
          </Typography>
          <Typography className={classes.role} align="center">
            {item.role}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

KeyIndividualsItem.propTypes = {
  biographies: PropTypes.array
};
