import React, { Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import { Header } from 'src/components/Header/Header';
import { Outlet } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '50%',
    margin: 'auto',
    overflowX: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 56
    }
  }
}));

function Auth() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default Auth;
