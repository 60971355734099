import { lazy } from 'react';

// Auth
const Login = lazy(() => import('src/components/Login'));
const ForgotPasswordForm = lazy(() => import('src/components/Login/ForgotPasswordForm'));

// Error
const Error401 = lazy(() => import('src/components/Error401'));
const Error404 = lazy(() => import('src/components/Error404'));
const Error500 = lazy(() => import('src/components/Error500'));
const Error403 = lazy(() => import('src/components/Error403/index'));

// MainLayout
const DealPage = lazy(() => import('./MainLayout/DealPage/TabPages/Deal/DealPage/DealPage'));
const Portfolios = lazy(() => import('./MainLayout/DealPage/TabPages/Portfolios'));
const Topics = lazy(() => import('./MainLayout/DealPage/TabPages/Topics'));
const DealPageDetails = lazy(() => import('./MainLayout/DealPageDetails'));
const PDFLayout = lazy(() => import('./MainLayout/PDFLayout'));
const HomePage = lazy(() => import('./MainLayout/HomePage'));
const ReportsPage = lazy(() => import('./MainLayout/ReportsPage'));
const UserManagement = lazy(() => import('./MainLayout/OperationsPage/UserManagement'));
const TopicManagement = lazy(() => import('./MainLayout/OperationsPage/TopicManagement'));
const CategoryManagement = lazy(() => import('./MainLayout/OperationsPage/CategoryManagement'));
const PrivateContent = lazy(() => import('./MainLayout/OperationsPage/PrivateContent'));
const AnalyticsManagement = lazy(() => import('./MainLayout/OperationsPage/AnalyticsManagement'));
const Workflow = lazy(() => import('./MainLayout/OperationsPage/Workflow'));
const DealTypeManagement = lazy(() => import('./MainLayout/OperationsPage/DealTypeManagement'));
const ProductManagement = lazy(() => import('./MainLayout/OperationsPage/ProductManagement'));
const ReportViewPage = lazy(() => import('./MainLayout/ReportsPage/ReportViewPage'));
const TrackChanges = lazy(() => import('./MainLayout/DealPage/TabPages/TrackChanges/index'));

export {
  Login,
  ForgotPasswordForm,
  Error401,
  Error404,
  Error500,
  Error403,
  DealPage,
  Portfolios,
  Topics,
  DealPageDetails,
  PDFLayout,
  HomePage,
  ReportsPage,
  UserManagement,
  TopicManagement,
  CategoryManagement,
  PrivateContent,
  AnalyticsManagement,
  Workflow,
  DealTypeManagement,
  ProductManagement,
  ReportViewPage,
  TrackChanges
};
