import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DOWNLOAD_FILE_ERROR_MESSAGE,
  DOWNLOAD_FILE_SUCCESS_MESSAGE,
  QUICK_REFERENCE_BOOKMARKS,
  SHARED_PORTFOLIO
} from 'src/utils/constants/stringConstants';
import { FilterList, SaveAltOutlined } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { showAlert } from 'src/redux/actions';
import {
  getAvailableDealForPortfolio,
  getPortfolio,
  getPortfolioDeal,
  getSharedPortfolio,
  getSharedPortfolioDeal,
  putPortfolioToPortfolio
} from 'src/services/api/portfolio';
import { postAnalyticsEvent } from 'src/services/api/analytics';
import { exportComparisonTable } from 'src/services/api/comparison';
import Autocomplete from '@mui/material/Autocomplete';
import { getActiveTopicByPortfolio } from 'src/services/api/topic';
import { alertBody, getPortfolioItem } from 'src/MainLayout/DealPage/utils';
import { downloadRetryLimit } from 'src/utils/constants/numericConstants';
import usePrevious from 'src/utils/usePrevious';
import CompareTable from '../ComparisonTable/CompareTable';
import * as actionsCreator from '../context/comparison.actions';
import ComparisonContext from '../context/comparison.context';
import useStyles from './styles';
import { AddDealToPortfolioModal } from '../AddDealToPortfolioModal';
import {
  areCloIdsEqual,
  filterOptions,
  getUsedPortfolio,
  rightTable,
  setArrFromTopicsList
} from './utils';

function ComparisonPage() {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const {
    state: {
      portfolioList,
      portfolioTopics,
      sharedPortfolioList,
      portfolioDealCompareList: { deals, loading }
    },
    dispatch
  } = useContext(ComparisonContext);

  const { portfolioId } = useParams();
  const navigate = useNavigate();

  const initialState = {
    id: +portfolioId,
    name: '',
    category: null
  };
  const initialPageNumber = 1;

  const [includeOnlyMajorDocuments, setIncludeOnlyMajorDocuments] = useState(false);
  const [activePortfolio, setActivePortfolio] = useState({ ...initialState });
  const [dealsList, setDealsList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [leftTable, setLeftTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [hasMore, setHasMore] = useState(false);
  const [allPortfoliosState, setAllPortfoliosState] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const userRole = useSelector((state) => state.session.user.role);
  const prevPortfolioState = usePrevious(portfolioList.portfolio);
  const prevSharedPortfolioState = usePrevious(sharedPortfolioList.portfolio);

  const observer = useRef();
  const lastDealElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // eslint-disable-next-line consistent-return
  const getPortfolios = async () => {
    try {
      dispatch(actionsCreator.getPortfolioList());
      const data = await getPortfolio();
      dispatch(actionsCreator.getPortfolioListSuccess(data));
      return data;
    } catch (err) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err.message
        })
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const getSharedPortfolios = async () => {
    try {
      dispatch(actionsCreator.getSharedPortfolioList());
      const data = await getSharedPortfolio();
      dispatch(actionsCreator.getSharedPortfolioListSuccess(data));
      return data;
    } catch (err) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err.response?.data?.message || err?.message
        })
      );
    }
  };

  const postAnalytics = async (event, details) => {
    const data = {
      event,
      details
    };
    await postAnalyticsEvent(data);
  };

  const getPortfolioTopics = async () => {
    let leftTablePart = {};
    try {
      const data = await getActiveTopicByPortfolio(activePortfolio.id);
      dispatch(actionsCreator.getPortfolioTopics(data));
      leftTablePart = data.map((item) => ({
        id: uuid(),
        category: item.category,
        topics: item.topicNames
      }));

      setLeftTable(leftTablePart);
    } catch (err) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: err.response?.data?.message || err })
      );
    }
  };

  const getActivePortfolio = () => {
    const portfolioData = portfolioList.portfolio.find(
      (portfolio) => portfolio.portfolioDto.id === +portfolioId
    );

    const sharedPortfolio = sharedPortfolioList.portfolio.find(
      (shportfolio) => shportfolio.portfolioDto.id === +portfolioId
    );

    const sharedPortfolioData = sharedPortfolio && {
      category: SHARED_PORTFOLIO,
      ...sharedPortfolio?.portfolioDto
    };

    const active = !!portfolioData ? portfolioData?.portfolioDto : sharedPortfolioData;
    setActivePortfolio(active);
  };

  const { usedPortfolio, isUserPortfolio, isSharedPortfolio } = getUsedPortfolio(
    portfolioList,
    sharedPortfolioList,
    activePortfolio
  );

  useEffect(() => {
    postAnalytics('TAB_ACCESS', 'Comparison');
    dispatch(actionsCreator.getPortfolioListSuccess([]));
    dispatch(actionsCreator.getDealCompareListSuccess([]));

    Promise.all([getPortfolios(), getSharedPortfolios()]).then((values) => {
      dispatch(actionsCreator.getPortfolioListSuccess(values[0]));
      dispatch(actionsCreator.getSharedPortfolioListSuccess(values[1]));
      setAllPortfoliosState(true);
    });
  }, []);

  useEffect(() => {
    if (!portfolioList.loading && !sharedPortfolioList.loading) {
      getActivePortfolio();
    }
  }, [portfolioList.loading, sharedPortfolioList.loading]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDealForComparison = async (pageNum) => {
    const param = { params: { page: pageNum } };
    try {
      dispatch(actionsCreator.getDealCompareList());
      await getPortfolioTopics();
      let data;
      if (isUserPortfolio) {
        data = await getPortfolioDeal(activePortfolio.id, param);
      } else if (isSharedPortfolio) {
        data = await getSharedPortfolioDeal(activePortfolio.id, param);
        setActivePortfolio((prevState) => ({
          ...prevState,
          category: SHARED_PORTFOLIO
        }));
      }

      const dealsData = usedPortfolio.cloDtoList;
      const dealList = data?.portfolioClos;
      const dealItems = dealList.map((i) =>
        Object.assign(
          i,
          dealsData.find((j) => j.id === i.cloId)
        )
      );
      setHasMore(dealList.length >= 10);
      // eslint-disable-next-line no-unused-expressions
      pageNumber > initialPageNumber
        ? dispatch(actionsCreator.updateDealCompareListSuccess(dealItems))
        : dispatch(actionsCreator.getDealCompareListSuccess(dealItems));
    } catch (error) {
      dispatch(actionsCreator.getDealCompareListError());
      dispatch(actionsCreator.updateDealCompareListSuccess([]));
      dispatch(actionsCreator.getDealCompareListSuccess([]));
    }
  };

  const getDealForPortfolio = async () => {
    try {
      dispatch(actionsCreator.getDealForPortfolioList());
      const data = await getAvailableDealForPortfolio(activePortfolio.id);
      dispatch(actionsCreator.getDealForPortfolioListSuccess(data));
      setDealsList(data);
    } catch (err) {
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err.message
        })
      );
    }
  };

  const addDealsToPortfolios = async (dealIds) => {
    try {
      setPageNumber(initialPageNumber);
      await putPortfolioToPortfolio(activePortfolio.id, dealIds);
      await getPortfolios();
    } catch (err) {
      if (err.response.data.status === 404) {
        dispatch(actionsCreator.getPortfolioListSuccess([]));
      }
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err.response?.data?.message || err.message
        })
      );
    }
  };

  const handleChange = (event, portfolio) => {
    setActivePortfolio((prevState) => ({ ...prevState, ...portfolio }));
    setPageNumber(initialPageNumber);
  };

  useEffect(() => {
    if (activePortfolio && activePortfolio.id && +portfolioId !== activePortfolio.id) {
      navigate(`/deal/page/comparison/${activePortfolio.id}`);
    }

    setIncludeOnlyMajorDocuments(false);
  }, [activePortfolio?.id]);

  useEffect(() => {
    if (activePortfolio?.id) {
      if (
        !areCloIdsEqual(portfolioList.portfolio, prevPortfolioState, activePortfolio.id) ||
        !areCloIdsEqual(sharedPortfolioList.portfolio, prevSharedPortfolioState, activePortfolio.id)
      ) {
        getDealForComparison(initialPageNumber);
      }

      window.scrollTo(0, 0);
    }
  }, [portfolioList.portfolio, activePortfolio?.id, sharedPortfolioList.portfolio]);

  useEffect(() => {
    if (activePortfolio?.id && allPortfoliosState) {
      getDealForComparison(pageNumber);
    }
  }, [activePortfolio?.id]);

  useEffect(() => {
    if (
      activePortfolio &&
      activePortfolio?.id &&
      allPortfoliosState &&
      pageNumber !== initialPageNumber
    ) {
      getDealForComparison(pageNumber);
    }
  }, [pageNumber]);

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : 'close';

  const selectOption = ({ target }) => {
    setIncludeOnlyMajorDocuments(target.value === 'true');
  };

  function renderTable() {
    const portfolioDeals = usedPortfolio?.cloDtoList || [];
    const sortedDataForTable = rightTable(deals, includeOnlyMajorDocuments).sort(
      (a, b) => a.position - b.position
    );
    return deals.length > 0 && leftTable.length ? (
      <CompareTable
        lastDealElementRef={lastDealElementRef}
        leftTable={leftTable.filter((i) => i?.category !== QUICK_REFERENCE_BOOKMARKS)}
        rightTable={sortedDataForTable}
        includeOnlyMajorDocuments={includeOnlyMajorDocuments}
        portfolio={activePortfolio}
      />
    ) : (
      <Typography className={classes.marginTop} align="center" gutterBottom>
        {!portfolioTopics.length && !!portfolioDeals.length ? (
          <>There are no categories available for this product to compare.</>
        ) : (
          <>
            Add Deals to the current Portfolio or select a different Portfolio to see the
            comparison.
          </>
        )}
      </Typography>
    );
  }

  const handleExport = async () => {
    setExportLoading(true);
    const requestId = uuid();
    const params = { requestId };
    const body = {
      includeOnlyMajorDocuments,
      portfolioShared: sharedPortfolioList.portfolio.some(
        (portfolio) => portfolio.portfolioDto.id === activePortfolio.id
      )
    };
    try {
      let requestState = { status: null, message: null, count: 0 };
      do {
        // eslint-disable-next-line no-await-in-loop
        const { url, exportStatus, message } = await exportComparisonTable(
          activePortfolio.id,
          body,
          params
        );
        requestState = { status: exportStatus, count: requestState.count + 1, message };
        if (url) {
          setExportLoading(false);
          postAnalytics('EXPORT', 'Comparison');
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
        }
      } while (requestState.status === 408 && requestState.count < downloadRetryLimit);
      dispatchRedux(
        showAlert(
          alertBody(requestState, DOWNLOAD_FILE_SUCCESS_MESSAGE, DOWNLOAD_FILE_ERROR_MESSAGE)
        )
      );
    } catch (err) {
      setExportLoading(false);
      dispatchRedux(
        showAlert({
          isShown: true,
          type: 'error',
          message: err?.response?.data?.message || err.message
        })
      );
    }
  };
  const availablePortfolios = setArrFromTopicsList(portfolioList, sharedPortfolioList);
  const uniqueProducts = new Set(
    availablePortfolios.reduce((accumulator, currentValue) => {
      if (currentValue.product !== undefined) {
        accumulator.push(currentValue.product);
      }
      return accumulator;
    }, [])
  );

  return (
    <div className={classes.root}>
      <Grid container item justifyContent="space-between">
        <Grid item xs={3}>
          <Autocomplete
            autoHighlight
            disableClearable
            size="small"
            filterOptions={filterOptions}
            options={availablePortfolios}
            value={activePortfolio}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            onChange={(event, portfolio) => handleChange(event, portfolio)}
            renderInput={(params) => (
              <TextField {...params} label="Select portfolio" variant="outlined" />
            )}
            renderOption={(props, option, selected) =>
              getPortfolioItem(props, option, selected, uniqueProducts.size > 1)
            }
          />
        </Grid>

        <Grid item>
          <Button
            disabled={!activePortfolio?.id}
            className={userRole === 'TrialUsers' ? classes.hidden : classes.redButton}
            startIcon={<SaveAltOutlined />}
            onClick={handleExport}
          >
            Export
          </Button>
          <Tooltip disableInteractive title="Options">
            <span>
              <IconButton
                disabled={!activePortfolio?.id}
                aria-describedby={id}
                onClick={handleClick}
                size="medium"
              >
                <FilterList />
              </IconButton>
            </span>
          </Tooltip>
          <AddDealToPortfolioModal
            key={`modal${activePortfolio?.category}`}
            getDealForPortfolio={getDealForPortfolio}
            dealsList={dealsList}
            portfolio={activePortfolio}
            addDealsToPortfolios={addDealsToPortfolios}
            amountDealsInPortfolio={usedPortfolio?.cloDtoList?.length}
          />
        </Grid>
      </Grid>

      {anchorEl && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Card>
            <CardContent>
              <FormControl component="fieldset">
                <RadioGroup value={includeOnlyMajorDocuments} onChange={selectOption}>
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Show current document only for each deal"
                  />
                  <FormControlLabel
                    value
                    control={<Radio />}
                    label="Show major documents only for each deal"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Popover>
      )}

      {loading && <LinearProgress />}
      {exportLoading && <LinearProgress />}

      {activePortfolio?.id ? (
        renderTable()
      ) : (
        <Typography className={classes.marginTop} align="center" gutterBottom>
          Select one of your portfolios to see comparison results
        </Typography>
      )}
    </div>
  );
}

export default ComparisonPage;
