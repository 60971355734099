import React, { useEffect } from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UserPool from 'src/UserPool';
import { login, logout } from 'src/redux/actions';
import { getCurrentDealDocument } from 'src/services/api/deal';

const permitPages = [
  '/about-us',
  '/home',
  '/about-us/bio/1',
  '/about-us/bio/2',
  '/about-us/bio/3',
  '/about-us/bio/4',
  '/about-us/bio/5',
  '/about-us/bio/6',
  '/about-us/bio/7',
  '/auth/login'
];
const oldPages = '/deal-pdf/';

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatchRedux = useDispatch();

  const saveUser = (payload) => {
    const params = {
      email: payload.email,
      role: payload['cognito:groups'][0],
      last_name: payload.family_name,
      first_name: payload.given_name
    };
    dispatchRedux(login(params));
  };

  const redirectOldUrl = async () => {
    const checkOldUrl = location.pathname.includes(oldPages);
    if (checkOldUrl) {
      const dealId = location.pathname.replace(/[^0-9]/g, '');
      if (!dealId) {
        return;
      }
      const data = await getCurrentDealDocument(dealId);
      if (location.search.includes('labelId')) {
        navigate(`/pdf/deal/${dealId}/document-pdf/${data}${location.search}`);
      } else navigate(`/pdf/deal/${dealId}/document-pdf/${data}`);
    } else if (matchPath({ path: '/deal/:deal', exact: true }, location.pathname)) {
      const dealId = location.pathname.replace(/[^0-9]/g, '');
      if (!dealId) {
        return;
      }
      const data = await getCurrentDealDocument(dealId);
      navigate(`/deal/${dealId}/document/${data}`);
    }
  };

  const checkPermissions = () => {
    if (permitPages.some((url) => url === location.pathname)) {
      return;
    }
    navigate('/auth/login');
  };

  useEffect(() => {
    sessionStorage.setItem('prevLocation', location.pathname);
    if (location.pathname === '/auth/login') {
      dispatchRedux(logout());
    }

    const currentUser = UserPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((error, activeSession) => {
        if (error) {
          dispatchRedux(logout());
          navigate('/auth/login');
        } else {
          currentUser.getUserData((err, res) => {
            if (
              location.pathname === '/operations' &&
              activeSession.getIdToken().payload['cognito:groups'][0] === 'Admins'
            ) {
              navigate('operations/topic-management');
            }
            if (
              location.pathname === '/operations' &&
              activeSession.getIdToken().payload['cognito:groups'][0] === 'Editors'
            ) {
              navigate('/operations/workflow');
            }
            if (res) {
              const checkUserEmailVerification = res.UserAttributes.some(
                (attr) => attr.Name === 'email_verified' && attr.Value === 'true'
              );
              if (checkUserEmailVerification) {
                saveUser(activeSession.getIdToken().payload);
                redirectOldUrl();
              } else {
                // this is a way to clear cached user data and get new one next time
                currentUser.setSignInUserSession(activeSession);
                checkPermissions();
              }
            }
          });
        }
      });
    } else {
      checkPermissions();
    }
  }, [location.pathname]);

  return <div>{children}</div>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
