import React, { Suspense, useReducer } from 'react';
import { Paper, Divider, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import OperationsPageHeader from './OperationsPageHeader';
import operationsContext from './context/operations.context';
import OperationsReducer, { initialState } from './context/operations.reducer';
import useStyles from './styles';

function OperationsPage() {
  const classes = useStyles();
  const [state, dispatch] = useReducer(OperationsReducer, initialState);
  const userRole = useSelector((reduxState) => reduxState.session.user.role);

  const adminTabs = [
    { link: '/operations/user-management', name: 'Users' },
    { link: '/operations/topic-management', name: 'Topics' },
    { link: '/operations/category-management', name: 'Categories' },
    { link: '/operations/private-content', name: 'Private Content' },
    { link: '/operations/analytics', name: 'Analytics' },
    { link: '/operations/workflow', name: 'Workflow' },
    { link: '/operations/product', name: 'Product' },
    { link: '/operations/deal-type', name: 'Deal Type' }
  ];

  const editorTabs = [{ link: '/operations/workflow', name: 'Workflow' }];

  const tabs = userRole === 'Admins' ? adminTabs : editorTabs;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <operationsContext.Provider value={{ state, dispatch }}>
      <Paper className={classes.contentContainer} elevation={0}>
        <OperationsPageHeader tabs={tabs} />
        <Divider />
        <Suspense fallback={<LinearProgress />}>
          <Outlet />
        </Suspense>
      </Paper>
    </operationsContext.Provider>
  );
}

export default OperationsPage;
