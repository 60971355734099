import React from 'react';
import { Grid } from '@mui/material';
import { GoogleMap } from 'src/components/GoogleMap';
import * as constants from 'src/utils/constants/stringConstants';
import { Address } from './components/Address';
import { useStyles } from './styles';

export const Locations = () => {
  const classes = useStyles();
  const { LONDON_ADDRESS, LONDON_CONTACT, NEWYORK_ADDRESS, NEWYORK_CONTACT } = constants;
  return (
    <Grid className={classes.root}>
      <Grid container className={classes.location}>
        <Address
          item
          classes={classes}
          city="London"
          country="United Kingdom"
          address={LONDON_ADDRESS}
          contact={LONDON_CONTACT}
        />
        <GoogleMap item location={LONDON_ADDRESS} />
      </Grid>
      <Grid container className={classes.location}>
        <Address
          item
          classes={classes}
          city="New York"
          country="United States"
          address={NEWYORK_ADDRESS}
          contact={NEWYORK_CONTACT}
        />
        <GoogleMap item location={NEWYORK_ADDRESS} />
      </Grid>
    </Grid>
  );
};
